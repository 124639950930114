import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, interval, Observable, Subject} from "rxjs";
import {mergeMap, startWith} from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  localStorageKey = 'di-notifications';
  
  notifications$: BehaviorSubject<INotificationItem[]> = new BehaviorSubject<INotificationItem[]>([])
  notificationsCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  constructor(private httpClient: HttpClient) { }
  
  loadNotifications() {
   const savedData = this.getStoredNotifications();
    interval( 15 * 60 * 1000)// runs every 15 minutes
    .pipe(
      startWith(0),
      mergeMap(() => this.httpClient.get<INotificationItem[]>('monetizer_api/api/notifications/get_notifications'))
    )
    .subscribe(data => {
      const preparedData = data.map(item => ({
        ...item,
        read: savedData.filter(st => st.id === item.id).length > 0
      }));
      this.notifications$.next(preparedData);
      this.notificationsCount$.next(preparedData.filter(d => !d.read).length)
      
    })
  }
  private getStoredNotifications(): {id: string}[] {
    const savedData = JSON.parse(localStorage.getItem(this.localStorageKey) as string ?? null);
    return savedData ?? [];
  }
  saveNotification(id: string) {
    this.notificationsCount$.next(this.notificationsCount$.value - 1)
    const savedData = this.getStoredNotifications();
    savedData.push({id});
    localStorage.setItem(this.localStorageKey, JSON.stringify(savedData))
  }
  
  readAll() {
    if (!this.notifications$.value) return
    this.notifications$.next(this.notifications$.value.map(i => {
      if (i.read) return i;
      this.saveNotification(i.id);
      return {
        ...i,
        read: true
      }
    }))
  }
}

export interface INotificationItem {
  id: string;
  title: string;
  type: string;
  message: string;
  link: string;
  link_text: string;
  publish_date: string;
  read: boolean;
  days_ago: string;
}


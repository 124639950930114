import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verify-notice',
  templateUrl: './verify-notice.component.html',
  styleUrls: ['./verify-notice.component.scss']
})
export class VerifyNoticeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import {ActionReducer, ActionReducerMap, MetaReducer} from "@ngrx/store/src/models";
import {userReducer, UserState} from "./user/user.reducer";
import {viewReducer, ViewState} from "./view/view.reducer";
import {domainReducer, DomainState} from "./domain/domain.reducer";

export interface AppState {
  user: UserState,
  // domain: DomainState,
  view: ViewState
}

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state, action) {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [debug];

export const reducers: ActionReducerMap<AppState> = {
  user: userReducer,
  // domain: domainReducer,
  view: viewReducer
}

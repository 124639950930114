import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {authenticateSuccess, loadUserInfo} from "./core/store/user/user.actions";
import {isUserAuthenticated, userLanguage} from "./core/store/user/user.selector";
import {environment} from "../environments/environment";
import {TranslateService} from "@ngx-translate/core";

import { locale, loadMessages } from 'devextreme/localization';

import ptMessages from 'devextreme/localization/messages/pt.json';
import jaMessages from 'devextreme/localization/messages/ja.json';
import esMessages from 'devextreme/localization/messages/es.json';

import {AppState} from "./core/store/app.reducer";
import {getLanguage} from "./core/store/view/view.selectors";
import {setLanguage} from "./core/store/view/view.actions";
import {DataLayerService} from "./core/services/datalayer.service";
import {CookieService} from "./core/services/cookie.service";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  
  /**
   * Check if left menu is opened
   * @type {boolean}
   */
  isLeftMenuShown: boolean = false;
  
  title = 'DataImpulse';
  
  authenticated: boolean = false;
  
  interfaceLang: string = 'en';
  
  user: any;
  
  reloadUserInfo: any;
  
  constructor(
      private store: Store<AppState>,
      private translateService: TranslateService,
      private cookieService: CookieService,
      private dataLayerService: DataLayerService
  ) {
    // init lang
    const browserLng = this.translateService.getBrowserLang();
    this.translateService.addLangs(['en', 'ja', 'pt', 'es']);
    this.translateService.setDefaultLang('en');
    this.initMessages();
    
    this.store.select(isUserAuthenticated).subscribe(auth => {
      this.authenticated = auth;
      if(this.authenticated)
        this.updateUserInfo();
    })
  
    this.store.select(userLanguage).subscribe(userLng => {
      console.log('store.select(userLanguage)', userLng)
      if(userLng && userLng?.match(/^(en|ja|pt|es)$/)) {
        this.store.dispatch(setLanguage({language: userLng}))
      }
    })
    
    this.store.select(getLanguage).subscribe(storeLng => {
      if(storeLng === undefined) {
        this.interfaceLang = browserLng?.match(/^(en|ja|pt|es)$/) ? browserLng : 'en';
        console.log('AppComponent dispatch setLanguage', this.interfaceLang)
        this.store.dispatch(setLanguage({language: this.interfaceLang}))
      } else {
        this.interfaceLang = storeLng.match(/^(en|ja|pt|es)$/) ? storeLng : 'en';
        console.log('AppComponent use this.interfaceLang', this.interfaceLang)
        this.translateService.use(this.interfaceLang);
        // if (this.interfaceLang === "ja") loadMessages(jaMessages);
        // if (this.interfaceLang === "pt") loadMessages(ptMessages);
        locale(this.interfaceLang);
      }
    })
    
    const user = JSON.parse(<string>localStorage.getItem('currentUser'));
    const token = localStorage.getItem('authToken');
    if (token && user) {
      this.store.dispatch(authenticateSuccess({user,token}))
      this.user = user;
    }
    
    this.dataLayerService.watchDataLayer().subscribe(e => {
      if(this.authenticated && e.event === 'cookie_consent_update' && this.cookieService.getCookie('cookieyes-consent')) {
        let consentCookie = this.cookieService.getCookie('cookieyes-consent');
        if(consentCookie.trim()) {
          this.dataLayerService.setConsentData(consentCookie.trim()).pipe(take(1)).subscribe(result => {});
        }
      }
    });
  }
  
  updateUserInfo() {
    // if(!this.reloadUserInfo) {
    //   this.reloadUserInfo = setInterval(() => {
    //     console.log('timer...' + new Date());
    //     if(document.hasFocus()){
    //       this.store.dispatch(loadUserInfo());
    //       console.log('request')
    //     } else {
    //       console.log('skip request')
    //     }
    //   }, 10000);
    //
    // }
  }
  
  initMessages() {
    loadMessages(ptMessages);
    loadMessages(jaMessages);
    loadMessages(esMessages);
  }
  
  initLanguage(browserLng?: string | undefined, storeLng?: string | undefined, userLng?: string | undefined) {
    console.log('AppComponent lang from store', storeLng)
    if(storeLng === undefined) {
      console.log('AppComponent browser lang', browserLng)
      this.interfaceLang = userLng?.match(/^(en|ja|pt|es)$/) ? userLng : (browserLng?.match(/^(en|ja|pt|es)$/) ? browserLng : 'en');
      console.log('AppComponent dispatch setLanguage', this.interfaceLang)
      this.store.dispatch(setLanguage({language: this.interfaceLang}))
    } else {
      this.interfaceLang = storeLng.match(/^(en|ja|pt|es)$/) ? storeLng : 'en';
      console.log('AppComponent use this.interfaceLang', this.interfaceLang)
      this.translateService.use(this.interfaceLang);
    }
  }
  
  getLangISO(language: string) {
    if (!language) return 'en';
    
    if (language === "russian") return 'ru';
    if (language === "german") return 'de';
    if (language === "spanish") return 'es';
    if (language === "french") return 'fr';
    
    return 'en';
  }
  
  ngOnInit() {
    this.initIntercom();
    locale(this.interfaceLang);
  }
  
  /**
   * Show/hide left menu toggle handler
   * @return {void}
   */
  menuToggle(): void {
    this.isLeftMenuShown = !this.isLeftMenuShown;
  }
  
  initIntercom() {
    (window as any).Intercom('boot', {
      app_id: environment.intercomAppId, domain: environment.appUrl, vertical_padding: 100, hide_default_launcher: false
    });
  }
  
  ngOnDestroy() {
    if (this.reloadUserInfo) {
      clearInterval(this.reloadUserInfo);
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ipCountRound'
})
export class IpCountRoundPipe implements PipeTransform {

  transform(value: number): string {
    if(value <= 10) {
      return ' (~10 IP`s)';
    }
    
    return ' (' + (Math.ceil(value / 10) * 10).toString() +  ' IP`s)';
  }

}

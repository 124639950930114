import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {RegisterFormModel} from "../../../../models/register-form.model";

@Component({
  selector: 'step-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent implements OnInit, AfterViewInit {
  
  form!: FormGroup;
  
  @Input() formContent: Partial<RegisterFormModel> = {};
  
  @Output() updateParentForm: EventEmitter<{ formData: Partial<RegisterFormModel>, valid: boolean }> = new EventEmitter<{ formData: Partial<RegisterFormModel>, valid: boolean }>();
  constructor() {
  
    this.form = new FormGroup({
      firstName: new FormControl(undefined, [
        Validators.required,
      ]),
      lastName: new FormControl(undefined, [
        Validators.required,
      ]),
      company: new FormControl(undefined),
      phone: new FormControl(undefined),
    })
  }
  
  ngOnInit(): void {
    // this.updateParentForm.emit({
    //   formData: this.form.value,
    //   valid: false
    // });
    this.form.valueChanges.subscribe(formData => {
      this.updateParentForm.emit({
        formData,
        valid: this.form.valid
      });
    })
  }
  
  ngAfterViewInit() {
    setTimeout(()=> {
      this.form.patchValue(this.formContent);
    }, 0)
  }
  
  /**
   * Getter for form email control
   * @type {AbstractControl}
   */
  get firstName(): AbstractControl | null {
    return this.form.get('firstName');
  }
  
  /**
   * Getter for form email control
   * @type {AbstractControl}
   */
  get lastName(): AbstractControl | null {
    return this.form.get('lastName');
  }

}

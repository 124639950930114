<div class="form" [formGroup]="form">
	<div class="row">
		<div class="col-md">
			<div class="form-group mb-2">
				<label>First Name <span class="red">*</span></label>
				<input formControlName="firstName" type="text" class="form-control" id="firstNameGroup" placeholder="" autocomplete="off">
				<mat-error *ngIf="firstName?.hasError('required') && firstName?.touched">
					This field is <strong>required</strong>
				</mat-error>
			</div>
		</div>
		<div class="col-md">
			<div class="form-group mb-2">
				<label>Last Name <span class="red">*</span></label>
				<input formControlName="lastName" type="text" class="form-control" id="lastNameGroup" placeholder="" autocomplete="off">
				<mat-error *ngIf="lastName?.hasError('required') && lastName?.touched">
					This field is <strong>required</strong>
				</mat-error>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md">
			<div class="form-group mb-2">
				<label>Company</label>
				<input formControlName="company" type="text" class="form-control" id="companyGroup" placeholder="" autocomplete="off">
			</div>
		</div>
		<div class="col-md">
			<div class="form-group mb-2">
				<label>Phone Number</label>
				<input formControlName="phone" type="text" class="form-control" id="phoneGroup" autocomplete="off">
			</div>
		</div>
	</div>
</div>

import {Injectable} from '@angular/core';
import {CookieService} from "./cookie.service";
import {PurchaseInfo} from "../models/purchase-info.model";

@Injectable({
  providedIn: 'root'
})
export class GaService {

  gaInstance: any;

  constructor(private cookieService: CookieService) {
  }

  initGa(){
    if ((window as any).ga && (window as any).ga.getAll) {
      this.gaInstance = (window as any).ga;
    }
  }

  getGaCid(){
    if(!this.gaInstance) this.initGa();

    let ga_cid;
    if(this.gaInstance){
      const tracker = this.gaInstance.getAll()[0];
      ga_cid = tracker.get('clientId');
      console.log('GaService 1 - ga_cid: ', ga_cid);
    } else {
      console.log('no ga');
    }

    if(!ga_cid){
      const _ga = this.cookieService.getCookie('_ga');
      ga_cid = _ga.split('.').slice(-2).join(".");
      console.log('GaService 2 - ga_cid: ', ga_cid);
    }

    return ga_cid;
  }

  // changed
  
  public successRegistration(user_id: number, provider: string) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'sign_up_start',
      'user_id': user_id,
      'event_label': provider
    });
  }
  
  public successVerification(user_id: number, provider: string) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'sign_up',
      'user_id': user_id,
      'event_label': provider
    });
  }
  
  public firstLogin(provider?: string) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'first_login'
    });
  }
  
  public successLogin(provider: string) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'login',
      'event_label': provider
    });
  }
  
  public addToCart(purchaseInfo: PurchaseInfo) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: purchaseInfo.currency,
        value: purchaseInfo.value,
        items: [
          {
            item_id: purchaseInfo.id,
            item_name: purchaseInfo.name,
            price: purchaseInfo.price,
            quantity: purchaseInfo.quantity
          }
        ]
      }
    });
  }
  
  public beginCheckout(purchaseInfo: PurchaseInfo) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        currency: purchaseInfo.currency,
        value: purchaseInfo.value,
        items: [
          {
            item_id: purchaseInfo.id,
            item_name: purchaseInfo.name,
            price: purchaseInfo.price,
            quantity: purchaseInfo.quantity
          }
        ]
      }
    });
  }
  
  public purchase(purchaseInfo: PurchaseInfo) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        affiliation: purchaseInfo.affiliation,
        payment_type: purchaseInfo.payment_system,
        purchase_type: purchaseInfo.purchase_type,
        transaction_id: purchaseInfo.transaction_id,
        value: purchaseInfo.value,
        currency: purchaseInfo.currency,
        coupon: purchaseInfo.coupon,
        items: [
          {
            item_name: purchaseInfo.name,
            price: purchaseInfo.price,
            quantity: purchaseInfo.quantity
          }
        ]
      }
    });
  }
  
  public getConsentParams() {
    let consentCookie = this.cookieService.getCookie('cookieyes-consent');
    const obj = consentCookie.split(',').reduce((acc, keyValue) => {
      const [key, value] = keyValue.split(':');
      acc[key] = value;
      return acc;
    }, {} as { [key: string]: string });
    
    console.log(obj);
    
    return obj;
  }
  
  
  

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'doubleBraces'
})
export class DoubleBracesPipe implements PipeTransform {

  transform(value: string | number): string {
    return `{{${value}}}`;
  }

}

<h2 class="text-center my-3">Sign Up</h2>

<ul id="sign-up-progressbar">
	<li *ngFor="let i of stepsArray" [class.active]="currentStep$.value >= i">
		step {{i}}
	</li>
</ul>

<div *ngIf="currentStep$.value === 1">
	<step-email-password
		[formContent]="formData"
		(updateParentForm)="updateStepForm($event)"
	></step-email-password>
</div>
<div *ngIf="currentStep$.value === 2">
	<step-personal-info
		[formContent]="formData"
		(updateParentForm)="updateStepForm($event)"></step-personal-info>
</div>
<div *ngIf="currentStep$.value === 3">
	<step-contact-info
		[formContent]="formData"
		(updateParentForm)="updateStepForm($event)"
	></step-contact-info>
</div>
<div *ngIf="currentStep$.value === 4">
	<step-use-case
		[formContent]="formData"
		(updateParentForm)="updateStepForm($event)"
	></step-use-case>
	<div class="mt-4">
		<label class="form-check form-check-inline">
			<input type="checkbox" [formControl]="acceptTerms" class="form-check-input">
			<span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">I agree to the
										<a href="https://dataimpulse.com/terms-of-services/"
										   class="ms-1 link-primary" target="_blank">Terms of Service</a> and <a href="https://dataimpulse.com/privacy-policy/" class="ms-1 link-primary" target="_blank">Privacy Policy</a></span>
		</label>
		<div *ngIf="acceptTerms?.hasError('required')" class="invalid-field red">
			You must agree to our Terms of service and Privacy Policy
		</div>
	</div>
</div>
<div class="form-steps d-flex align-items-center justify-content-between mt-3">
	<button (click)="prevStep()" *ngIf="currentStep$.value > 1" class="btn btn-outline-secondary">Back to step {{currentStep$.value - 1}}</button>
	<div *ngIf="currentStep$.value <= 1"></div>
	<button (click)="nextStep()" [disabled]="currentStep$.value >= stepsCount || !isCurrentFormValid$.value
" class="btn btn-default" *ngIf="currentStep$.value <= stepsCount - 1">Next</button>
	<button (click)="captchaRef.execute()" *ngIf="currentStep$.value === stepsCount
" class="btn btn-success" [disabled]="!isCurrentFormValid$.value || !acceptTerms.valid">Complete</button>
</div>

<hr>
<div class="text-center">Already have an account? <a routerLink="/sign-in">Sign In</a></div>

<!--<form class="w-100 my-3" [formGroup]="form">-->
<!--  <div class="form-group">-->
<!--    <div class="input-group mb-2">-->
<!--      <div class="input-group-prepend">-->
<!--        <div class="input-group-text"><img src="./assets/images/lock.png" alt="" class="img-responsive"></div>-->
<!--      </div>-->
<!--      <input formControlName="name" type="text" class="form-control" id="loginNameGroup" placeholder="Your name">-->
<!--    </div>-->
<!--    <mat-error *ngIf="name?.hasError('minlength')">-->
<!--      Min length of name <strong>2</strong> symbols-->
<!--    </mat-error>-->
<!--  </div>-->
<!--  <div class="form-group">-->
<!--    <div class="input-group mb-2">-->
<!--      <div class="input-group-prepend">-->
<!--        <div class="input-group-text"><img src="./assets/images/user.png" alt="" class="img-responsive"></div>-->
<!--      </div>-->
<!--      <input formControlName="email" type="email" class="form-control" id="loginEmailGroup" placeholder="Email">-->
<!--    </div>-->
<!--    <mat-error *ngIf="email?.hasError('email') && !email?.hasError('required')">-->
<!--      Please enter a valid email address-->
<!--    </mat-error>-->
<!--    <mat-error *ngIf="email?.hasError('required') && email?.touched">-->
<!--      Email is <strong>required</strong>-->
<!--    </mat-error>-->
<!--  </div>-->
<!--  <div class="form-group">-->
<!--    <div class="input-group mb-2">-->
<!--      <div class="input-group-prepend">-->
<!--        <div class="input-group-text"><img src="./assets/images/lock.png" alt="" class="img-responsive"></div>-->
<!--      </div>-->
<!--      <input formControlName="password" type="password" class="form-control" id="loginPasswordGroup" placeholder="Create password (8+ characters)">-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="form-group">-->
<!--    <div class="input-group mb-2">-->
<!--      <div class="input-group-prepend">-->
<!--        <div class="input-group-text"><img src="./assets/images/lock.png" alt="" class="img-responsive"></div>-->
<!--      </div>-->
<!--      <input formControlName="passwordConfirm" type="password" class="form-control" id="loginPasswordConfirmGroup" placeholder="Repeat a password">-->
<!--    </div>-->
<!--  </div>-->
<!--&lt;!&ndash;  <div class="form-group" *ngIf="affiliateID">&ndash;&gt;-->
<!--&lt;!&ndash;    <div class="input-group mb-2">&ndash;&gt;-->
<!--&lt;!&ndash;      <div class="input-group-prepend">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="input-group-text"><img src="./assets/images/auth-gift.png" alt="" class="img-responsive"></div>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;      <input formControlName="coupon" type="text" class="form-control" id="couponGroup" placeholder="Coupon Code">&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;  </div>&ndash;&gt;-->
<!--  <div *ngIf="affiliateID">-->
<!--    <div class="alert alert-success text-center mb-2" role="alert" >-->
<!--      <img src="./assets/images/auth-gift.png" class="icon-img mb-1 mr-1" alt=""> Referral link bonus<br/>-->
<!--      $5 will be added to your account-->
<!--    </div>-->
<!--&lt;!&ndash;    <button type="button" class="btn btn-link w-100" (click)="showCouponForm()">I want to use coupon code instead</button>&ndash;&gt;-->
<!--  </div>-->
<!--  -->
<!--  -->
<!--  -->
<!--  &lt;!&ndash;		<div class="form-group form-check">&ndash;&gt;-->
<!--  &lt;!&ndash;			<input type="checkbox" class="form-check-input" id="exampleCheck1">&ndash;&gt;-->
<!--  &lt;!&ndash;			<label class="form-check-label" for="exampleCheck1">Check me out</label>&ndash;&gt;-->
<!--  &lt;!&ndash;		</div>&ndash;&gt;-->
<!--  <button type="submit" class="btn btn-default w-100  " [disabled]="form.invalid || (isLoading | async)" (click)="captchaRef.execute()"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="(isLoading | async)"></span> Sign Up</button>-->
<!--</form>-->
<!--<div class="d-flex flex-column align-items-center">-->
<!--  <div class="both-lined"><p>or use</p></div>-->
<!--  <div class="social-container">-->
<!--    <a (click)="goToThirdParty('google')" class="social"><img src="./assets/images/google.svg" [width]="16"-->
<!--                                                              alt="Google" title="Google"></a>-->
<!--&lt;!&ndash;    <a (click)="goToThirdParty('github')" class="social"><img src="./assets/images/github.svg" [width]="16"&ndash;&gt;-->
<!--&lt;!&ndash;                                                              alt="GitHub" title="GitHub"></a>&ndash;&gt;-->
<!--  </div>-->
<!--</div>-->
<!--<p class="text-center">Already have an account? <a routerLink="/sign-in">Log in</a></p>-->

<re-captcha size="invisible" #captchaRef (resolved)="$event && completeRegister($event)"
            [siteKey]="SITE_ID_NG">
</re-captcha>

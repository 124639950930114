<div *ngIf="state$ | async as state" class="container">
	<h5 *ngIf="state.cart.length">Cart:</h5>
	<div class="card">
		<div class="card-body">
			<table class="table table-hover shopping-cart-wrap" *ngIf="state.cart.length">
				<thead class="text-muted">
				<tr>
					<th scope="col">Product</th>
					<th scope="col" width="120">Quantity</th>
					<th scope="col" width="120">Price</th>
					<th scope="col" width="200" class="text-right">Action</th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let item of state.cart; let i = index; trackBy: trackByFn">
					<td>
						<h6 class="title text-truncate">{{item.label}}</h6>
						<!--					<dl class="param param-inline small">-->
						<!--						<dt>Product: </dt>-->
						<!--						<dd>{{item.product}}</dd>-->
						<!--					</dl>-->
						<dl class="param param-inline small">
							<dt>Type:</dt>
							<dd>{{item.charge_type}}</dd>
						</dl>
					</td>
					<td>
						
						<mat-form-field appearance="outline">
							<mat-label>Amount <span>{{item.charge_type === 'requests' ? 'requests' : item.charge_type === 'traffic' ? 'GBs' : ''}}:</span></mat-label>
<!--							<input matInput type="number"-->
<!--							       [min]="item.charge_type === 'requests'? 1000 : 1" [step]="item.charge_type === 'requests'? 1000 : 1" class="example-right-align" (input)="addCountToCart(item, $event)" (change)="roundCount(item, $event)" [value]="item.count">-->
							
							<input matInput type="number"
							       [min]="item.min_count" [step]="item.charge_type === 'requests'? 1000 : 1"
							       class="example-right-align" (input)="addCountToCart(item, $event)" (change)="roundCount(item, $event)" [value]="item.count">
						</mat-form-field>
					</td>
					<td>{{item.amount| currency:'USD':'symbol':'1.2-2' }}</td>
					<td class="text-right">
						<button mat-flat-button type="button" (click)="remove(item, i)" class="but-remove">Remove</button>
					</td>
				</tr>
				</tbody>
				<tfoot *ngIf="state.tot > 0">
				<tr>
					<td>
						<button (click)="clear()" type="button" class="btn btn-outline-secondary">Clear Cart</button>
					</td>
					<td></td>
					<td>Total: {{state.tot | currency:'USD':'symbol':'1.2-2' }}</td>
					<td class="text-right">
						<button type="button" class="btn btn-default" (click)="checkout(state)" [disabled]="loading"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span> Checkout</button>
					</td>
				</tr>
				</tfoot>
			</table>
			<div class="container mt-2">
				<div class="row">
					<div *ngFor="let item of state.store | callback: filterStore" class="col-sm-6">
						<div class="plan-card mb-3">
							<div class="plan-card-header">
								<h5>{{item.label}}</h5> <span class="badge badge-subscription" *ngIf="item.has_subscription">Subscription</span>
							</div>
							<div class="plan-card-body">
								<ul>
									<li>Charge Type: {{item.charge_type}}</li>
									<li>Product Type: {{item.product}}</li>
									<li>Threads count: {{item.threads}}</li>
									<li>PRICE: <strong>{{item.price_per_value | currency:'USD':'symbol':'1.2-2' }} / {{item.charge_type === 'requests' ? 1000 : 1 }} {{item.charge_type === 'traffic' ? 'GBs' : item.charge_type}}</strong></li>
								</ul>
								
							</div>
							<div class="">
								<button class="btn btn-default" (click)="addItemToCart(item)" type="button">Add to Cart</button>
								<div class="two-lines">
									OR
								</div>
								<button class="btn btn-default btn-default-outline" (click)="openDialogSubscription(item)" type="button" *ngIf="!item.has_subscription">Make Subscription</button>
								<button class="btn btn-default btn-default-outline" (click)="openCancelSubscription(item)" type="button" *ngIf="item.has_subscription">Cancel Subscription</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</div>

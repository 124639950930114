import {Component, Input, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "../../../store/app.reducer";
import {getLanguage} from "../../../store/view/view.selectors";
import {setLanguage} from "../../../store/view/view.actions";
import {isUserAuthenticated, selectUser} from "../../../store/user/user.selector";

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  
  @Input() size: string = 'full'
  
  isDropdownOpen: boolean = false;
  
  currentLanguage?: {key: string, value: string} = {key: 'en', value: 'English'};
  
  languagesList: {key: string, value: string}[] = [
    {key: 'en', value: 'English'},
    {key: 'pt', value: 'Portuguese'},
    {key: 'ja', value: '日本語 (Japanese)'},
    {key: 'es', value: 'Spanish'}
  ];
  
  authenticated: boolean = false;
  
  constructor(private store: Store<AppState>) {
    this.store.select(isUserAuthenticated).subscribe(auth => {
      this.authenticated = auth;
    })
  
    // console.log('construct LanguageSelectorComponent');
    this.store.select(getLanguage).subscribe(lng => {
      // console.log('LanguageSelectorComponent lang from store', lng)
      // console.log('LanguageSelectorComponent curr lang', this.currentLanguage?.key)
      if(lng !== undefined && lng !== this.currentLanguage?.key) {
        console.log('fire to change lng', lng, this.currentLanguage?.key)
        this.changeLanguage(lng);
      }
      
    })
  
  }
  
  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  
  changeLanguage(langCode: string): void {
    this.currentLanguage = this.languagesList.find( l => l.key === langCode);
    console.log("Language changed to: ", this.currentLanguage);
    this.store.dispatch(setLanguage({language: langCode}));
  }
  
  closeDropdown(): void {
    this.isDropdownOpen = false;
  }
  
  ngOnInit(): void {
  }

}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CartItem} from "../../../models/cart-item.model";
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";

export function GreaterThan(controlName: string, checkControlName: string): ValidatorFn {
  return (controls: AbstractControl) => {
    const control = controls.get(controlName);
    const checkControl = controls.get(checkControlName);
    
    if (checkControl?.errors && !checkControl.errors.greater) {
      return null;
    }
    
    if (control?.value <= checkControl?.value) {
      checkControl?.setErrors({ greater: true });
      return { greater: true };
    } else {
      return null;
    }
  }
}

@Component({
  selector: 'app-subscription-dialog',
  templateUrl: './subscription-dialog.component.html',
  styleUrls: ['./subscription-dialog.component.scss']
})
export class SubscriptionDialogComponent implements OnInit {
  
  form: FormGroup;
  
  default_min: number;

  constructor(
      public dialogRef: MatDialogRef<SubscriptionDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: {item: CartItem}
  ) {
    this.default_min = data.item.charge_type === 'requests'? 1000 : 1;
    this.form = new FormGroup({
      count: new FormControl(this.default_min * 2, [
        Validators.required,
        Validators.min(this.default_min)
      ]),
      count_limit: new FormControl(1, [
        Validators.required,
        Validators.min(1)
        
      ]),
      amount: new FormControl(0, [
          Validators.required,
          Validators.min(1)
      ])
    }, {validators: GreaterThan('count', 'count_limit')});
  }
  
  countChange(e: any) {
    this.countLimit?.setValue(this.countLimit?.value);
  }
  
  get amount() {
    const count = this.data.item.charge_type === 'requests' ? Number(this.form.get('count')?.value) / 1000 : Number(this.form.get('count')?.value)
    return count * this.data.item.price_per_value;
  }

  ngOnInit(): void {
  }
  
  makeSubscription() {
   if (this.form.valid) {
     if (this.form.value) {
      this.dialogRef.close({formData: {...this.form.value, plan_id: this.data.item.plan_id, charge_type: this.data.item.charge_type}})
     }
   }
  }
  
  /**
   * Getter for count_limit control
   * @type {AbstractControl}
   */
  get countLimit(): AbstractControl | null {
    return this.form.get('count_limit');
  }
  
  /**
   * Getter for count control
   * @type {AbstractControl}
   */
  get count(): AbstractControl | null {
    return this.form.get('count');
  }

}

import {Action, createReducer, on} from "@ngrx/store";
import {setLanguage} from "./view.actions";

export interface ViewState {
  theme: string;
  language: string | undefined;
}

const initialState: ViewState = {
  theme: 'light',
  language: undefined
}

const _viewReducer = createReducer(
  initialState,
  on(setLanguage, (state, data: {language: string}) => state = {...state, language: data.language}),
)

export function viewReducer(state: ViewState | undefined, action: Action) {
  return _viewReducer(state, action)
}

<div class="notifications-modal">
	<div class="notifications-modal__header">
		<h6>Notifications</h6>
		<a type="button" href="javascript:void(0)" (click)="readAll()" class="read-all" *ngIf="notifications.length > 0">Read All</a>
	</div>
	<hr>
	
		<dx-scroll-view
			#scrollView
			id="scrollview"
			[height]="250"
			*ngIf="notifications.length; else emptyInbox"
		[scrollByContent]="false"
			[showScrollbar]="'onHover'"
		><div class="notifications-wrapper">
			<app-notifications-item *ngFor="let notification of notifications;" [notificationItem]="notification"></app-notifications-item>
		</div>
		</dx-scroll-view>
	
	
</div>

<ng-template #emptyInbox>
	<div class="empty-inbox">System notifications and updates will be displayed here.</div>
</ng-template>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberPolarity'
})
export class NumberPolarityPipe implements PipeTransform {

  transform(value: number): string {
    return value > 0 ? `+${value}` : value < 0 ? `-${value}` : `${value}`;
  }

}

import { createSelector } from '@ngrx/store';
import {AppState} from "../app.reducer";
import {UserState} from "./user.reducer";
import {environment} from "../../../../environments/environment";

export const selectUser = (state: AppState) => state.user;

export const getUserData = createSelector(
  selectUser,
  (state: UserState) => state.user
)

export const isUserAuthenticated = createSelector(
  selectUser,
  (state: UserState) => state.authenticated
)

export const isLoading = createSelector(
	selectUser,
	(state: UserState) => state.loading
)

export const isHasPlans = createSelector(
	selectUser,
	(state: UserState) => !!state.user?.plans?.length
)

export const getPlans = createSelector(
	selectUser,
	(state: UserState) => state.user?.plans ?? []
)

export const prices = createSelector(
	selectUser,
	(state: UserState) => state.user?.prices
)

export const userLanguage = createSelector(
	selectUser,
	(state: UserState) => state.user?.language
)

export const getAffiliateLink = createSelector(
	selectUser,
	(state: UserState) => state.user?.affiliate_link ?? 'https://dataimpulse.com/?aff=' + state.user?.id
)

// export const isUserVerified = createSelector(
//   selectUser,
//   (state: UserState) => !!state.user?.verified
// )

import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {AppState} from "../core/store/app.reducer";
import {AccountApi} from "./api/account.api";
import {AddPayment} from "../core/models/add-payment.model";
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {ChangePass} from "./models/change-password.model";
import {transformHttpError} from "../core/utils/utils";
import {updateUserInfo} from "../core/store/user/user.actions";
import {Invoice} from "./models/invoice.model";
import {IUserPlan} from "../core/models/user.model";
import {UserPayoutMethodItem} from "./models/user-payout-method-item.model";

@Injectable()
export class AccountFacade {
	/**
	 * Constructor for AuthFacade
	 * @param {Store<AppState>} store
	 * @param {AuthApi} api
	 */
	constructor(private store: Store<AppState>,
	            private api: AccountApi) {
	}
	
	changePassword(formData: ChangePass): Observable<any> {
		return this.api.changePassword(formData.oldPassword, formData.password, formData.passwordConfirm)
	}
	
	updateUserData(formData: any) {
		this.store.dispatch(updateUserInfo(formData));
	}
	
	deleteUser() {
		return this.api.deleteUser();
	}
	
	// getInvoices(): Observable<Invoice[]>{
	// 	return this.api.getInvoices().pipe(
	// 		map(response => response.data)
	// 	)
	// }
	
	getInvoices(): Observable<Invoice[]>{
		return this.api.getInvoices().pipe(
			map(response =>{
				return response.data.length ? response.data.map(item => {
					const date: Date = new Date(item.datetime.replace(/-/g,'/'));
					return {
						...item,
						datetime: new Intl.DateTimeFormat('en-US', {
							day: "numeric",
							month: "numeric",
							year: "numeric",
							hour: 'numeric',
							minute: 'numeric',
							second: 'numeric'
						}).format(date)
					}
				}) : response.data;
			})
		)
	}
	
	getAllPlans(): Observable<IUserPlan[]> {
		return this.api.getAllPlans().pipe(map(res => res.data));
	}
	
	getUserPayoutMethods(): Observable<UserPayoutMethodItem[]> {
		return this.api.getUserPayoutMethods();
	}
	
	editUserPayoutMethods(payoutMethodsChanges: any): Observable<any> {
		return this.api.editUserPayoutMethods(payoutMethodsChanges);
	}
	
	confirmPayoutChanges(token: string): Observable<any> {
		return this.api.confirmPayoutChanges(token);
	}
	
	setConsentData(cookieData: string): Observable<any> {
		return this.api.setConsentData(cookieData);
	}

}

import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {
    authenticate,
    authenticateError,
    authenticateSuccess,
    signOut,
    signOutSuccess,
    signUp,
    signUpError,
    signUpSuccess,
    sendEmailAction,
    sendEmailActionSuccess,
    sendEmailActionError,
    sendNewPassAction,
    sendNewPassActionSuccess,
    sendNewPassActionError,
    verifyUserAction,
    verifyUserActionSuccess,
    verifyUserActionError,
    authenticateOneTime,
    updateUserInfo,
    updateUserInfoSuccess,
    updateUserInfoError,
    authenticateThirdParty,
    authenticateThirdPartySuccess,
    initPlans,
    initPlansSuccess,
    initPlansError, loadUserInfo
} from "./user.actions";
import {catchError, exhaustMap, map, tap, timeout} from "rxjs/operators";
import {of} from "rxjs";
import {User} from "../../models/user.model";
import {AuthApi} from "../../../auth/api/auth.api";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {transformHttpError} from "../../utils/utils";
import {MatDialog} from "@angular/material/dialog";
import {SuccessVerifyComponent} from "../../../auth/containers/sign-in/success-verify/success-verify-component.component";
import {ErrorVerifyComponent} from "../../../auth/containers/sign-in/error-verify/error-verify-component.component";
import {AccountApi} from "../../../account/api/account.api";
import {GaService} from "../../services/ga.service";
import {CookieService} from "../../services/cookie.service";
import {HeaderService} from "../../modules/header/header.service";
import {VerifyNoticeComponent} from "../../../auth/containers/sign-in/verify-notice/verify-notice.component";

declare global {
  interface Window { dataLayer: any[]; }
}
@Injectable()
export class UserEffects {

  private clearLocalStorage(): void {
    localStorage.removeItem('userId');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('authToken');
    localStorage.removeItem('last_requested_url');
    localStorage.removeItem('cart_items');
  }

  private setUserToLocalStorage(token: string, user: User): void {
    localStorage.setItem('authToken', token);
    localStorage.setItem('currentUser', JSON.stringify(user));
    localStorage.setItem('userId', user.id.toString());
  }

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticate),
      exhaustMap((actions) => this.authApi.userLogin(actions.email.toLowerCase(), actions.password, actions.recaptcha, actions.visitor_id)
        .pipe(
          map(userData => {
            // update user "limits"
            // maybe routing somewhere
            let navigateRoute = '/dashboard';
             if (localStorage.getItem('last_requested_url') && localStorage.getItem('last_requested_url').trim()) {
                 const lastUrlData = JSON.parse(localStorage.getItem('last_requested_url').trim());
                 localStorage.removeItem('last_requested_url');
                 const lastUrl = lastUrlData?.url;
                 const lastUrlTime = lastUrlData?.time;
                 if (lastUrl && lastUrlTime && (Date.now() - lastUrlTime) < (60 * 60 * 6)) {
                     navigateRoute = lastUrl
                 }
             }
            this.setUserToLocalStorage(userData.data.token, userData.data.user);

            // this.gaService.gaAuthSuccess(userData.data.user.id, userData.data.user.first_visit);
            if (userData.data.user.first_visit) {
              this.gaService.firstLogin();
            }

            this.router.navigate([navigateRoute])
            return authenticateSuccess({...userData.data});
          }),
          catchError((error) => {
              if (error.error.errors) {
                  this.showSnackBarError(transformHttpError(error.error.errors));
              } else {
                  this.showSnackBarError(error.error.message);
              }
            return of(authenticateError(error));
          })
        )
      )
    )
  );
    
    loginByThirdParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authenticateThirdParty),
            exhaustMap(payload => {
                const referrerParams = this.cookieService.getAllReferrerParams();

                const postData = {
                    ...payload,
                    referrer_params: referrerParams,
                }
                return this.authApi.userLoginByThirdParty(postData)
                .pipe(
                    map(userData => {
                        this.setUserToLocalStorage(userData.data.token, userData.data.user);
                        this.clearRegisterCookies();
                        // this.router.navigate(['/dashboard'])
                        if(userData.data.user.is_registration && userData.data.user.socialite_provider) {
                            this.gaService.successRegistration(userData.data.user.id, userData.data.user.socialite_provider);
                            this.gaService.successVerification(userData.data.user.id, userData.data.user.socialite_provider);
                        }
                        return authenticateThirdPartySuccess({...userData.data});
                    }),
                    catchError((error) => {
                        if (error.error.errors) {
                            this.showSnackBarError(transformHttpError(error.error.errors));
                        } else {
                            this.showSnackBarError(error.error.message);
                        }
                        this.router.navigate(['/sign-in'])
                        return of(authenticateError(error));
                    })
                )
}
            )
        )
    );
    
    loginByThirdPartySuccess$ = createEffect( () =>
        this.actions$.pipe(
            ofType(authenticateThirdPartySuccess),
            tap(() => {
                localStorage.removeItem('affiliateID');
                this.router.navigate(['/dashboard'])
            })
        ),
        { dispatch: false }
    )
  
  loginOneTime$ = createEffect( () =>
    this.actions$.pipe(
        ofType(authenticateOneTime),
        exhaustMap((actions) => this.authApi.loginOneTime(actions.uid, actions.onetime_pass)
            .pipe(
                map(response => response.data),
                map(userData => {
                    this.setUserToLocalStorage(userData.token, userData.user);
                    
                    this.router.navigate(['/dashboard'])
                    return authenticateSuccess({...userData});
                }),
                catchError((error) => {
                    if (error.error.errors) {
                        this.showSnackBarError(transformHttpError(error.error.errors));
                    } else {
                        this.showSnackBarError(error.error.message);
                    }
                    this.router.navigate(['/sign-in'])
                    return of(authenticateError(error));
                })
            )
        )
    )
  )

  logOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signOut),
      map(() => {
        // update client limits
        // maybe routing somewhere
        this.clearLocalStorage();
        this.router.navigate(['/auth']);
        
        return signOutSuccess();
      })
    )
  )

  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUp),
      exhaustMap(actions => {
        const affiliateID = localStorage.getItem('affiliateID') || undefined;
        const referrerParams = this.cookieService.getAllReferrerParams();

        return this.authApi.userRegister(actions, referrerParams)
            .pipe(
              map((userData: any) => {
                this.clearRegisterCookies();

                this.router.navigate(['/sign-in']);
                this.dialog.open(VerifyNoticeComponent, {
                  panelClass: 'verify-success-popup',
                  maxWidth: '600px'
                })
                this.gaService.successRegistration(userData.data.user_id, 'email')
                return signUpSuccess({...userData.data});
              }),
              catchError(error => {
              if (error.error.errors) {
                  this.showSnackBarError(transformHttpError(error.error.errors));
              } else {
                  this.showSnackBarError(error.error.message);
              }
                return of(signUpError(error));
              })
            )
        }
      )
    )
  );

  sendEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendEmailAction),
      exhaustMap(actions => this.authApi.sendEmailForReset(actions.emailForReset.toLowerCase(), actions.recaptcha)
        .pipe(
          map((email: any) => {
            this._snackBar.open(email.msg, 'Close', {duration: 5000, panelClass: ['snack-bar-success']});
            return sendEmailActionSuccess(email);
          }),
          catchError(error => {
            this._snackBar.open(error.error.message, 'Close', {duration: 5000, panelClass: ['snack-bar-error']});
            return of(sendEmailActionError(error));
          })
        )
      )
    )
  );

  sendNewPass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendNewPassAction),
      exhaustMap(actions => this.authApi.sendNewPass(actions.newPassData.user_id, actions.newPassData.token, actions.newPassData.password, actions.recaptcha)
      .pipe(
        map((passData: any) => {
          this._snackBar.open(passData.msg, 'Close', {duration: 5000, panelClass: ['snack-bar-success']});
          this.router.navigate(['/sign-in']);
          return sendNewPassActionSuccess(passData);
        }),
        catchError(error => {
          this._snackBar.open(error.error.message, 'Close', {duration: 5000, panelClass: ['snack-bar-error']});
          return of(sendNewPassActionError(error));
        })
      )
    )
  ));

  verifyUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(verifyUserAction),
      exhaustMap(actions => this.authApi.verifyUser(actions.user_id, actions.activation_code)
      .pipe(
        map((verifyData: any) => {
            this.router.navigate(['/sign-in'])
            this.dialog.open(SuccessVerifyComponent, {
              panelClass: 'verify-success-popup',
            })
          this.gaService.successVerification(+actions.user_id, 'email');
          return verifyUserActionSuccess(verifyData);
        }),
        catchError(error => {
            this.router.navigate(['/sign-in'])
            let errorMessage = 'Email verification failed. Please contact our support team.';
            if (error.error.errors.user_id) {
                errorMessage = error.error.errors.user_id
            }
            this.dialog.open(ErrorVerifyComponent, {
              data: { message: errorMessage },
              panelClass: 'verify-error-popup'
            })
          return of(verifyUserActionError(error));
        })
      ))
    )
  )
   
   updateSettings$ = createEffect(() =>
       this.actions$.pipe(
           ofType(updateUserInfo),
           exhaustMap((actions) => this.accountApi.updateUser(actions.name)
               .pipe(
                   map(userData => {
                       localStorage.setItem('userId', userData.data.user.id.toString());
                       localStorage.setItem('currentUser', JSON.stringify(userData.data.user));
                       this._snackBar.open('User Successfully updated', 'Close', {duration: 5000, panelClass: ['snack-bar-success']});
                       return updateUserInfoSuccess({...userData.data});
                   }),
                   catchError((error) => {
                       if (error.error.errors) {
                          this.showSnackBarError(transformHttpError(error.error.errors));
                       } else {
                           this.showSnackBarError(error.error.message);
                       }
                       return of(updateUserInfoError(error));
                   })
               )
           )
       )
   )
  
  initUserPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initPlans),
      exhaustMap(actions => this.headerService.initPlans()
        .pipe(
          map(plansData => {
            let user = JSON.parse(<string>localStorage.getItem('currentUser'));
            user.plans = plansData
            return initPlansSuccess({user})
          }),
          catchError(error => {
            this._snackBar.open(error.error.message, 'Close', {duration: 5000, panelClass: ['snack-bar-error']});
            return of(initPlansError(error));
          })
        )
      )
      // map(plansData => {
      //   let user = JSON.parse(<string>localStorage.getItem('currentUser'));
      //   user.plans = plansData.plans
      //   return initPlansSuccess({user})
      // })
    ))
  
  initUserPlansSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initPlansSuccess),
      tap((data) => {
          localStorage.setItem('userId', data.user.id.toString());
          localStorage.setItem('currentUser', JSON.stringify(data.user));
      })
    ), { dispatch: false })
    
    updateUserInfoSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserInfoSuccess),
      tap((data) => {
          // console.log('effect updateUserInfoSuccess: ', data.user)
          localStorage.setItem('userId', data.user.id.toString());
          localStorage.setItem('currentUser', JSON.stringify(data.user));
      })
    ), { dispatch: false })
    
    
    loadUserInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadUserInfo),
            exhaustMap((actions) => this.accountApi.getUserInfo()
                .pipe(
                    map(userData => {
                        console.log('!!! effect update user info', userData.data)
                        return updateUserInfoSuccess({...userData.data});
                    }),
                    catchError((error) => {
                        return of(updateUserInfoError(error));
                    })
                )
            )
        )
    );
    
  constructor(
    private actions$: Actions,
    private authApi: AuthApi,
    private accountApi: AccountApi,
    private router: Router,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private gaService: GaService,
    private cookieService: CookieService,
    private headerService: HeaderService
  ) {
  }

  private showSnackBarError(message: string) {
    this._snackBar.open(message, 'X', {
      duration: 5000,
      panelClass: ['snack-bar-error']
    });
  }

  private clearRegisterCookies() {
      localStorage.removeItem('affiliateID');

      this.cookieService.deleteCookie('referrer_register');
        this.cookieService.deleteCookie('referrer');
        for (let i = 1; i < 6; i++) {
            this.cookieService.deleteCookie('referrer_params_' + i);
        }
        this.cookieService.deleteCookie('aff_id');
    }
}

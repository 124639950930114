<h2 mat-dialog-title>Make subscription for plan "{{data.item.label}}"</h2>
<mat-dialog-content class="mat-typography">
	<hr/>
	<form [formGroup]="form">
		<div class="form-group row">
			<div class="col-sm-3 col-form-label">Plan name:</div>
			<div class="col-sm-9">
				<span>{{data.item.label}}</span>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-sm-3 col-form-label">Price:</div>
			<div class="col-sm-9">
				<span>{{data.item.amount | currency:'USD':'symbol':'1.2-2' }} / {{data.item.charge_type === 'requests' ? 1000 : 1 }} {{data.item.charge_type === 'traffic' ? 'GBs' : data.item.charge_type}}</span>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-sm-3 col-form-label">Amount <span>{{data.item.charge_type === 'requests' ? 'requests' : data.item.charge_type === 'traffic' ? 'GBs' : ''}}: </span></div>
			<div class="col-sm-9">
				<input type="number" [min]="data.item.charge_type === 'requests' ? 1000 : 1" [step]="data.item.charge_type === 'requests'? 1000 : 1" class="form-control" formControlName="count" id="inputCount" (change)="countChange($event)">
				<mat-error *ngIf="count?.hasError('required') && count?.touched">
					Amount must be greater than <strong>0</strong>
				</mat-error>
				<mat-error *ngIf="count?.hasError('min') && count?.touched">
					Count must be greater than <strong>{{default_min}}</strong>
				</mat-error>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-sm-3 col-form-label">Minimum amount <span>{{data.item.charge_type === 'requests' ? 'requests' : data.item.charge_type === 'traffic' ? 'GBs' : ''}} to renew subscription: </span></div>
			<div class="col-sm-9">
				<input type="number" [min]="0" [step]="default_min" class="form-control" formControlName="count_limit" id="inputMinCount">
				<mat-error *ngIf="countLimit?.hasError('required') && countLimit?.touched">
					Minimum amount is <strong>required</strong>
				</mat-error>
				<mat-error *ngIf="countLimit?.hasError('min') && countLimit?.touched">
					Minimum amount must be greater than <strong>0</strong>
				</mat-error>
				<mat-error *ngIf="countLimit?.hasError('greater')">
					Minimum amount must be smaller than <strong>Amount</strong>
				</mat-error>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-sm-3 col-form-label">Total Price:</div>
			<div class="col-sm-9">
				<input type="hidden" formControlName="amount" [ngModel]="amount">
				<strong>{{amount | currency:'USD':'symbol':'1.2-2'}}</strong>
			</div>
		</div>
	</form>
	<hr/>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button mat-dialog-close="true">Cancel</button>
	<button mat-button cdkFocusInitial class="btn btn-outline-primary" (click)="makeSubscription()">Pay</button>
</mat-dialog-actions>


import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from "@angular/material/snack-bar";
import {IStripeCustomerInfo} from "../../models/user.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-customer-info-dialog',
  templateUrl: './customer-info-dialog.component.html',
  styleUrls: ['./customer-info-dialog.component.scss']
})
export class CustomerInfoDialogComponent implements OnInit{
  
  customerInfoForm?: FormGroup;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {customerInfo: IStripeCustomerInfo, paymentMethod: string, amount: number},
              public dialog: MatDialog,
              private _snackBar: MatSnackBar, private formBuilder: FormBuilder) {
  }
  
  ngOnInit() {
    this.customerInfoForm = this.formBuilder.group({
      email: [this.data.customerInfo.email, Validators.required],
      name: [this.data.customerInfo.name],
      country: [this.data.customerInfo.country],
      state: [this.data.customerInfo.state],
      city: [this.data.customerInfo.city],
      address_line_1: [this.data.customerInfo.address_line_1],
      address_line_2: [this.data.customerInfo.address_line_2],
      postal_code: [this.data.customerInfo.postal_code],
      phone: [this.data.customerInfo.phone],
    });
  }
  
}

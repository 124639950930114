export const environment = {
  production: true,
  apiUrl: 'https://data.dataimpulse.com',
  logoUrl: './assets/images/logo-dataimpulse.png',
  // v3
  // recaptchaKey: '6LegMOUgAAAAAKe2KVX3tYP-5LSJ0ik-ZigqG3ld',
  // v2
  recaptchaKey: '6LdhCxwmAAAAAG0yAcLfHrVljYPuEzkrqT0lCdIJ',
  // storefrontUrl: 'dataimpulse.test.onfastspring.com/popup-dataimpulse',
  storefrontUrl: 'dataimpulse.onfastspring.com/popup-dataimpulse',
  dataAccessKey: 'E6ZSXYKHSMMJKZJ6R0N5NA',
  intercomAppId: 'yhwl2kwv',
  appUrl: 'app.dataimpulse.com',
  fingerprintPublicKey: 'hG8fTsCaFnRkBv1wFuRI',
  // stripePublicKey: 'pk_test_51NDR8HB2woammPw88Dqo3naQ5HXpPhvIkvSNu8d2L6eDjsNrzyjH223hU6PEzia2fuR5E7pAQlWfVh0tJLo2oGpn001BTQit3e',
  // stripePublicKey: 'pk_live_51NDR8HB2woammPw8SWvlX4QubsIfT3TXQYpZi47KswJsNBDPg2iVDS0sWbaea3urc25zYb6sGa6BmTOhpK2WFdvK00d9mpMFo2',
  //stripe US
  // stripePublicKey: 'pk_test_51OAF5DAWZZJCgSrYSNkyL2ezxw8pIg3IRPKUGVuakNMVErVIiSAVh1MlkmDn7IvcpcCr3z68mHb8oLhwLxXxPae700OqEMDT4N',
  stripePublicKey: 'pk_live_51OAF5DAWZZJCgSrYqXr3mOqEGv3Wp00uXZ15Qgq59G8JNHvXfqVsUGojRGWF2LhiFmdYdf69hM4sOvCRvp0W2AXQ00DOqvAF0O',
  paymentReturnUrl: 'https://app.dataimpulse.com/plans',
  balancePaymentReturnUrl: 'https://app.dataimpulse.com/reseller/deposit',
  measurementId: 'G-MTYMJ5QX8Q',
  minPayout: 100,
  //paddle
  paddleToken: 'live_c1cb0684bececa6e20f00691d67'
};

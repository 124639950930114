<section class="header nk-body bg-lighter npc-default has-sidebar no-touch">
  <div [@enter] class="nk-header nk-header-fixed is-light">
    <div class="container-fluid">
      <div class="nk-header-wrap">
<!--        <app-domain-control-->
<!--          (saveDomainDataToState)="saveDomainDataToState($event)">-->
<!--        </app-domain-control>-->
          <button mat-icon-button class="mobile-menu-icon" aria-label="Menu icon" (click)="menuToggleHandler()">
              <mat-icon>menu</mat-icon>
          </button>

        <div class="nk-header-tools">
          <app-notifications></app-notifications>
<!--            <div *ngIf="cartState$ | async as state" class="mr-3">-->
<!--                <app-cart-item [state]="state"></app-cart-item>-->
<!--            </div>-->
<!--          <app-language-selector></app-language-selector>-->
          <app-user-menu
            [isUserAuthenticated]="isUserAuthenticated$ | async"
            [userData] = "userData$ | async"
            (loginEvent)="login()"
            (logoutEvent)="logout()">
          </app-user-menu>
        </div>
      </div>
    </div>
  </div>

  <div class="bf" *ngIf="isHoliday">
    <strong>Black Friday : <span style="color:#FAAA07">+25%</span> Bonus Traffic 🔥  <a href="/plans"> GET NOW -></a></strong>
    <p style="font-size: 10px;">*When you pay for traffic during this promo, an additional +25% of the traffic you’re purchasing will be credited
      to your account
      .</p>
  </div>

</section>


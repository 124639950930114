import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from "@angular/router";
import {AlertComponent} from "./alert.component";
import {CoreModule} from "../../../core.module";



@NgModule({
	declarations: [
		AlertComponent
	],
	imports: [
		CommonModule,
		CoreModule,
		RouterModule
	],
	exports: [AlertComponent]
})
export class AlertModule { }

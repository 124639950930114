<div class="form" [formGroup]="form">
	<div class="form-group">
		<label>Email address <span class="red">*</span></label>
		<input formControlName="email" type="email" class="form-control" id="loginEmailGroup" autocomplete="off">
		<mat-error *ngIf="email?.hasError('email') && !email?.hasError('required')">
			Please enter a valid email address
		</mat-error>
		<mat-error *ngIf="email?.hasError('required') && email?.touched">
			Email is <strong>required</strong>
		</mat-error>
	</div>
	<div class="row">
		<div class="col-md">
			<div class="form-group">
				<label>Password <span class="red">*</span></label>
				<input formControlName="password" type="password" class="form-control" id="loginPasswordGroup" autocomplete="off">
				<mat-error *ngIf="password?.hasError('required') && password?.touched">
					Password is <strong>required</strong>
				</mat-error>
				<mat-error *ngIf="password?.hasError('minlength')">
					Min length of password <strong>8</strong>
				</mat-error>
			</div>
		</div>
		<div class="col-md">
			<div class="form-group">
				<label>Repeat Password <span class="red">*</span></label>
				<input formControlName="passwordConfirm" type="password" class="form-control" id="loginPasswordConfirmGroup">
				<mat-error *ngIf="passwordConfirm?.hasError('matching') && password?.touched && passwordConfirm?.touched">
					Confirm password must <strong>matches</strong>
				</mat-error>
			</div>
		</div>
	</div>
</div>

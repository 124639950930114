<section class="nk-body bg-lighter" [class.app]="authenticated">
  <div class="left-menu">
    <app-left-menu [isShown]="isLeftMenuShown"
                   (menuToggle)="menuToggle()" *ngIf="authenticated"
    ></app-left-menu>
  </div>

  <div [class]="(authenticated) ? 'py-4 content': ''">
<!--      <app-notification-line *ngIf="authenticated"></app-notification-line>-->
    <div class="left-bg" [class.hidden]="!isLeftMenuShown" (click)="menuToggle()"></div>
    <app-header (menuToggle)="menuToggle()" *ngIf="authenticated"></app-header>

    <router-outlet></router-outlet>
  </div>
</section>

import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from "@angular/material/snack-bar";
import {environment} from "../../../../environments/environment";

const STRIPE_COUNTRIES = ['AE', 'AT', 'AU', 'BE', 'BG', 'BR', 'CA', 'CH', 'CI', 'CR', 'CY', 'CZ', 'DE', 'DK', 'DO', 'EE',
  'ES', 'FI', 'FR', 'GB', 'GI', 'GR', 'GT', 'HK', 'HR', 'HU', 'ID', 'IE', 'IN', 'IT', 'JP', 'LI', 'LT', 'LU', 'LV', 'MT',
  'MX', 'MY', 'NL', 'NO', 'NZ', 'PE', 'PH', 'PL', 'PT', 'RO', 'SE', 'SG', 'SI', 'SK', 'SN', 'TH', 'TT', 'US', 'UY'];

@Component({
  selector: 'app-stripe-dialog',
  templateUrl: './stripe-dialog.component.html',
  styleUrls: ['./stripe-dialog.component.scss']
})
export class StripeDialogComponent implements OnInit, OnDestroy{
  
  stripe: any;
  
  elements: any;
  
  checkout: any;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: { payment_sum: number, client_secret: string, location_code: string, payment_method: string, payment_type: string, is_checkout: boolean},
              public dialog: MatDialog,
              private _snackBar: MatSnackBar) {
    
    this.stripe = (window as any).Stripe(environment.stripePublicKey);
  }
  
  ngOnInit() {
    if(this.data.is_checkout) {
      this.prepareStripeCheckout();
    } else {
      this.prepareStripeIntent();
    }
    
  }
  
  ngOnDestroy() {
    if(this.checkout) {
      this.checkout.unmount();
      this.checkout.destroy();
    }
  }
  
  async prepareStripeCheckout() {
  
    const options = {
      clientSecret: this.data.client_secret
    }
    
    this.checkout = await this.stripe.initEmbeddedCheckout(options);
    this.checkout.mount('#checkout');
  }
  
  checkoutOnComplete() {
  }
  
  prepareStripeIntent() {
  
    this.elements = this.stripe.elements(
        {
          clientSecret: this.data.client_secret,
          locale: this.data.location_code && STRIPE_COUNTRIES.includes(this.data.location_code.toUpperCase()) ? this.data.location_code.toUpperCase() : 'US',
        }
    );
    const paymentElementOptions = {
      // layout: "tabs",
      layout: "accordion",
      fields: {
        billingDetails: {
          name: 'auto',
          email: 'auto',
          phone: 'auto',
        }
      },
      paymentMethodOrder: this.data.payment_method === 'paypal' ? ['paypal'] : ['apple_pay', 'google_pay', 'card'],
    };
    const paymentElement = this.elements.create("payment", paymentElementOptions);
    paymentElement.mount("#payment-element");
  
    if(!environment.production) {
      const addressOptions = { mode: 'billing' };
      const addressElement = this.elements.create('address', addressOptions);
      addressElement.mount('#address-element');
    }
  
    const linkAuthenticationElement = this.elements.create("linkAuthentication");
    linkAuthenticationElement.mount("#link-authentication-element");
  }
  
  async stripeConfirmPayment() {
    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url: (this.data.payment_type === 'balance_pay' ? environment.balancePaymentReturnUrl : environment.paymentReturnUrl),
        // receipt_email: emailAddress,
      },
    });
    
    if (error.type === "card_error" || error.type === "validation_error") {
      console.error(error.message);
      this._snackBar.open(error.message, 'Close', {duration: 10000, panelClass: ['snack-bar-error']});
    } else {
      console.error("An unexpected error occurred.");
      this._snackBar.open('An unexpected error occurred.', 'Close', {duration: 10000, panelClass: ['snack-bar-error']});
    }
  }
  
}

<div class="">
  <div class="localStorage-notifications">
    <div *ngFor="let notif of localStorageNotifications"
         class="alert alert-dismissible"
         [ngClass]="'alert-' + notif.type"
    >
      <span [innerHTML]="notif.message"></span>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeAndSaveToLocalStorage(notif)">
        <span aria-hidden="true"><mat-icon>close</mat-icon></span>
      </button>
    </div>
  </div>
  <div
    [@myAnimation]="currentState"
    (@myAnimation.done)="closeAfterAnimation()"
    *ngIf="notificationConfig.visible"
    class="notification-line mb-3"
    [ngClass]="notificationConfig.type"
  >
    <div></div>
    <div class="notification-line__body">
      <p class="notification-line__text" [innerHTML]="notificationConfig.message"></p>
      <a *ngIf="notificationConfig.link && notificationConfig.link.href"
         [href]="notificationConfig.link.href">{{notificationConfig.link.text}}</a>
      <a *ngIf="notificationConfig.link && notificationConfig.link.routerLink"
         [routerLink]="notificationConfig.link.routerLink">{{notificationConfig.link.text}}</a>
    </div>
    <div>
      <div *ngIf="notificationConfig.showCloseBtn" class="close-btn">
        <span (click)="closeNotification()" class="icon icon-close"> <mat-icon>close</mat-icon> </span>
      </div>
    </div>
  </div>
</div>

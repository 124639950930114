import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {FormControl, Validators} from "@angular/forms";
import {RegisterFormModel} from "../../../../models/register-form.model";

@Component({
  selector: 'step-use-case',
  templateUrl: './use-case.component.html',
  styleUrls: ['./use-case.component.scss']
})
export class UseCaseComponent implements OnInit {

  selectedUseCase: string | null = null;
  
  customUseCaseCtrl!: FormControl;
  
  @Input() formContent: Partial<RegisterFormModel> = {};
  
  @Output() updateParentForm: EventEmitter<{ formData: Partial<RegisterFormModel>, valid: boolean }> = new EventEmitter<{ formData: Partial<RegisterFormModel>, valid: boolean }>();
  showOtherUseCaseField$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  useCases: string[] = [
    'Web scraping',
    'SEO scraping',
    'Ad verification',
    'Website testing',
    'Price comparison',
    'Brand protection',
    'Streaming content delivery',
    'Other'
  ];
  constructor() { }

  ngOnInit(): void {
  }
  
  
  useCaseChange(useCase: string) {
    if (useCase === 'Other') {
      this.showOtherUseCaseField$.next(true)
      this.customUseCaseCtrl = new FormControl('', [
        Validators.required,
        Validators.minLength(4)
      ])
      this.customUseCaseCtrl.valueChanges.subscribe(data => {
        this.emitFormUpdate(data);
      })
      return;
    }
    this.showOtherUseCaseField$.next(false);
    this.emitFormUpdate(useCase);
    return;
  }
  
  emitFormUpdate(data: string) {
    this.updateParentForm.emit({
      formData: {
        use_case: data,
      },
      valid: !!(this.customUseCaseCtrl?.valid || (this.selectedUseCase && this.selectedUseCase !== 'Other'))
    })
  }
}

import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {MustMatch} from "../../sign-up.component";
import {RegisterFormModel} from "../../../../models/register-form.model";

@Component({
  selector: 'step-email-password',
  templateUrl: './email-password.component.html',
  styleUrls: ['./email-password.component.scss']
})
export class EmailPasswordComponent implements OnInit, AfterViewInit{

  form: FormGroup;
  
  @Input() formContent: Partial<RegisterFormModel> = {};
  
  @Output() updateParentForm: EventEmitter<{ formData: Partial<RegisterFormModel>, valid: boolean }> = new EventEmitter<{ formData: Partial<RegisterFormModel>, valid: boolean }>();
  constructor() {
    this.form = new FormGroup({
      email: new FormControl(undefined, [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl(undefined, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(1000)
      ]),
      passwordConfirm: new FormControl(undefined, [
    
      ])
    }, {validators: MustMatch('password', 'passwordConfirm')})
  
    this.form.valueChanges.subscribe(formData => {
      this.updateParentForm.emit({
        formData,
        valid: this.form.valid
      });
    })
  }

  ngOnInit(): void {
  
  }
  
  ngAfterViewInit() {
    setTimeout(()=> {
      this.form.patchValue(this.formContent);
    }, 0)
  }
  
  /**
   * Getter for form email control
   * @type {AbstractControl}
   */
  get email(): AbstractControl | null {
    return this.form?.get('email');
  }
  
  /**
   * Getter for form password control
   * @type {AbstractControl}
   */
  get password(): AbstractControl | null {
    return this.form?.get('password');
  }
  
  /**
   * Getter for form confirm password control
   * @type {AbstractControl}
   */
  get passwordConfirm(): AbstractControl | null {
    return this.form?.get('passwordConfirm');
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {CartService} from "../cart.service";

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-icon.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit {
  @Input() state: any;
  constructor(private  cartService: CartService) { }

  ngOnInit(): void {
    // this.cartService.loadCart();
  }
  
  checkout(): void {
    this.cartService.checkout(this.state);
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { takeUntil } from 'rxjs/operators';
import {InfoLineModel, NotificationLineModel} from "../../../../models/notification-line.model";
import {HeaderService} from "../../header.service";
import {AppState} from "../../../../store/app.reducer";
import {Store} from "@ngrx/store";
import {isUserAuthenticated} from "../../../../store/user/user.selector";

@Component({
  selector: 'app-notification-line',
  templateUrl: './notification-line.component.html',
  styleUrls: ['./notification-line.component.css'],
  animations: [
    trigger('myAnimation', [
      state('state2', style({
        height: '0px',
        overflow: 'hidden',
      })),
      transition('*=>state2', animate('200ms'))
    ])
  ]
})
export class NotificationLineComponent implements OnInit, OnDestroy {

  /**
   * State for animation
   * @type {string}
   */
  currentState: string;

  /**
   * Configuration to notification message
   */
  notificationConfig: NotificationLineModel;

  /**
   * If closed button was clicked
   * @type {boolean}
   */
  closeBtnClicked: boolean;

  /**
   * Subject to complete all subscription on component destroy
   * @type {Subject}
   */
  private destroy$: Subject<boolean>;
  
  localStorageNotifications: InfoLineModel[] = [];

  /**
   * @ignore
   */
  constructor(private headerService: HeaderService, private store: Store<AppState>) {
    
    this.store.select(isUserAuthenticated).subscribe(data => {
      if (data) {
        this.headerService.checkPlansBalanceDaysLeft();
        this.headerService.getPlansErrorsSummary().subscribe(res => {
            if (res && res.length > 0) {
              let infoLine: NotificationLineModel;

              let messages: any[] = [];

              res.forEach(item => {
                if (item.errors && item.errors.length) {
                  item.errors.forEach(er => {
                    if (er.percentage > 1) {
                      if (er.error === 'PORT_BLOCKED') {
                        messages.push({
                          id: 'PORT_BLOCKED',
                          message: `Your Plan ${item.label} has the PORT_BLOCKED error because you tried to connect to a port that is closed by default. If you require access to closed ports write to our support team and provide details of your use case.`
                        });
                      }
                    }
                  })
                }
              });

              if(messages.length > 0) {
                messages.forEach(m => {
                  this.localStorageNotifications.push({
                    id: m.id,
                    message: m.message,
                    type: 'warning'
                  })
                })
                const savedData = JSON.parse(localStorage.getItem('di_notifications') as string ?? null);
                if (savedData) {
                  this.localStorageNotifications = this.localStorageNotifications.filter(el => {
                    return savedData.find((lc: any) => lc.id !== el.id)
                  })
                }
               
              }
            }
          });
      }
    })
    
    this.destroy$ = new Subject<boolean>();
  }
  
  closeAndSaveToLocalStorage(notif: InfoLineModel) {
    const oldDataRaw = localStorage.getItem('di_notifications') ?? null;
    const oldData = oldDataRaw ? JSON.parse(localStorage.getItem('di_notifications') as string) : null;
    let newData: any[] = [];
    if (oldData) {
      newData = [...oldData];
    }
    newData.push(notif);
    localStorage.setItem('di_notifications', JSON.stringify(newData));
    this.localStorageNotifications = this.localStorageNotifications.filter(el => el.id !== notif.id)
  }
  /**
   * Call on component init
   */
  ngOnInit(): void {
    this.headerService.trialLineSubject.pipe(takeUntil(this.destroy$)).subscribe((value: NotificationLineModel) => {
      if (value) {
        this.setConfig(value);
      } else {
        this.setDefaultConfig();
      }
    });
  }

  setConfig(config: any) {
    this.notificationConfig = {
      ...this.notificationConfig,
      ...config
    }
  }

  /**
   * Set notification default config
   */
  private setDefaultConfig(): void {
    this.notificationConfig = {
      type: 'default',
      message: null,
      link: {
        text: null,
        routerLink: null,
        href: null
      },
      showCloseBtn: false,
      visible: false
    };
  }

  /**
   * On button close notification
   */
  closeNotification(): void {
    this.currentState = 'state2';
    this.closeBtnClicked = true;
    this.headerService.showTrialLineSubject.next(false);
  }

  /**
   * Set config to default after hide animation
   */
  closeAfterAnimation(): void {
    this.closeBtnClicked ? this.setDefaultConfig() : false;
  }

  /**
   * Call on component destroy
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

}

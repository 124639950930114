import {Component, Input, OnInit} from '@angular/core';
import {INotificationItem, NotificationsService} from "../notifications.service";

@Component({
  selector: 'app-notifications-modal',
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.scss']
})
export class NotificationsModalComponent implements OnInit {

  @Input() notifications: INotificationItem[] = [];
  selectedNotification: string | null = null;
  constructor(private service: NotificationsService) { }

  ngOnInit(): void {
  }
  
  selectNotification(id: string) {
    this.selectedNotification = id
  }
  
  readAll() {
    this.service.readAll();
  }

}

<div class="form">
	<div class="row align-items-end">
		<div class="col-12">
			<h6 class="mb-2">Please describe how the proxies would be used from your side</h6>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<div class="input-group">
					<mat-form-field  appearance="outline">
						<mat-label>Use Case
						</mat-label>
						<mat-select [(value)]="selectedUseCase" (valueChange)="useCaseChange(selectedUseCase)">
							<mat-option [value]="option" *ngFor="let option of useCases">{{ option }}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="col-md-6" *ngIf="showOtherUseCaseField$.value">
			<div class="form-group">
				<label>Enter your use case <span class="red">*</span></label>
				<input [formControl]="customUseCaseCtrl" type="text" class="form-control" >
				<mat-error *ngIf="customUseCaseCtrl?.hasError('required') && customUseCaseCtrl?.touched">
					This field is <strong>required</strong>
				</mat-error>
				<mat-error *ngIf="customUseCaseCtrl?.hasError('minLength') && customUseCaseCtrl?.touched">
					Min length equals <strong>5</strong>
				</mat-error>
			</div>
		</div>
	</div>
</div>


import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";
import {NotificationLineModel} from "../../models/notification-line.model";
import {map, take, tap} from "rxjs/operators";
import {initPlans} from "../../store/user/user.actions";
import {AppState} from "../../store/app.reducer";
import {Store} from "@ngrx/store";

@Injectable({
  providedIn: 'root'
})
export class HeaderService implements OnDestroy{
  
  public trialLineSubject: BehaviorSubject<NotificationLineModel> = new BehaviorSubject<NotificationLineModel>(null);
  public showTrialLineSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  checkDaysLeftTimer: any;
  
  constructor(private httpClient: HttpClient, private store: Store<AppState>) {
  }
  
  checkPlansBalanceDaysLeft () {
    if(!this.checkDaysLeftTimer) {
      this.getPlansBalanceDaysLeft();
  
      this.checkDaysLeftTimer = setInterval(() => {
        if(document.hasFocus()){
          this.getPlansBalanceDaysLeft();
        } else {
        }
      }, 15*60*1000);
    }
  }
  
  getPlansBalanceDaysLeft() {
    this.httpClient.get<PlanDaysLeftItem[]>('monetizer_api/api/plans/get_days_left')
    .pipe(
        take(1)
    )
    .subscribe(res => {
      if (res && res.length > 0) {
        let infoLine: NotificationLineModel;
        
        let messages: string[] = [];
        
        res.forEach(item => {
          if(item.days_left < 6)
            messages.push(`${item.label}: The volume of your traffic will be enough for ${item.days_left} days more.`);
        });
        
        if(messages.length > 0) {
          infoLine = {
            message: messages.join('</br>'),
            showCloseBtn: true,
            type: 'warning',
            visible: true
          };
          
          this.trialLineSubject.next(infoLine);
          this.showTrialLineSubject.next(!!infoLine);
        }
      }
    });
  }
  
  getPlansErrorsSummary() {
    return this.httpClient.get<{data: ErrorsSummaryItem[]}>('monetizer_api/api/plans/get_errors_summary')
    .pipe(
      take(1),
      map(res => res.data)
    )
    // .subscribe(res => {
    //   if (res && res.length > 0) {
    //     let infoLine: NotificationLineModel;
    //
    //     let messages: string[] = [];
    //
    //     res.forEach(item => {
    //       if (item.errors && item.errors.length) {
    //         item.errors.forEach(er => {
    //           if (er.error === 'HOST_NOT_ALLOWED') {
    //             messages.push(`Your Plan ${item.label} has the HOST_NOT_ALLOWED error. If you want to obtain full access write to our support team and provide details of your use case. By doing so, we will be able to review your request for unblocking and ensure that our IP pool is not being used for any illegal activities.`);
    //           }
    //         })
    //       }
    //     });
    //
    //     if(messages.length > 0) {
    //       infoLine = {
    //         message: messages.join('</br>'),
    //         showCloseBtn: true,
    //         type: 'warning',
    //         visible: true
    //       };
    //
    //       this.trialLineSubject.next(infoLine);
    //       this.showTrialLineSubject.next(!!infoLine);
    //     }
    //   }
    // });
  }
  
  ngOnDestroy() {
    if(this.checkDaysLeftTimer) {
      clearInterval(this.checkDaysLeftTimer);
    }
  }
  
  initPlans() {
    return this.httpClient.get<{data: any}>('monetizer_api/api/plans/get_plans').pipe(
      map(res =>
        res.data
      )
    )
  }
}


export interface PlanDaysLeftItem {
  id: number;
  label: string;
  days_left: number;
}

export interface ErrorsSummaryItem {
  id: number;
  label: string;
  errors: ErrorsSummaryItemError[]
}

interface ErrorsSummaryItemError {
  error: string;
  count: number;
  percentage: number;
}

import {Component, HostListener, OnInit} from '@angular/core';
import {CartService, StateTree} from "./cart.service";
import {Observable} from "rxjs";
import {CartItem} from "../../models/cart-item.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {transformHttpError} from "../../utils/utils";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/app.reducer";
import {getUserData} from "../../store/user/user.selector";
import {take} from "rxjs/operators";
import {environment} from "../../../../environments/environment";
import {MatDialog} from "@angular/material/dialog";
import {SubscriptionDialogComponent} from "./subscription-dialog/subscription-dialog.component";
import {SubscriptionItem} from "../../models/subscription-item.model";
import {PlansList} from "../../models/plans-list.model";
import {ConfirmModalComponent} from "../parts/confirm-modal/confirm-modal.component";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  
  state$: Observable<StateTree>;
  
  loading: boolean = false;
  
  userPaymentSystem = 'paddle';
  
  // @Inject(MAT_DIALOG_DATA) public data: any,
  constructor(
      private cartService: CartService,
      private _snackBar: MatSnackBar,
      private store: Store<AppState>,
      private dialog: MatDialog
  ) {
    this.state$ = this.cartService.state$.pipe();
    this.store.select(getUserData).pipe(take(1)).subscribe(user => {
      if(user?.payment_system && user.payment_system !== '') {
        this.userPaymentSystem = user.payment_system;
        if(this.userPaymentSystem === 'fastspring')
          this.addFastspringScript();
      }
    });
  }
  
  ngOnInit(): void {
  }
  
  filterStore(storeItem: CartItem) {
    return !storeItem.trial
  }
  
  openCancelSubscription(row: CartItem) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      position: {
        top: '20px'
      },
      width: '400px',
      data: {
        title: 'Are you sure you want to cancel the <strong>'+ row.label + '</strong> subscription?'
      }
    })
    
    dialogRef.afterClosed().subscribe(result => {
      if (result === true)
        this.cartService.cancelSubscription(row.plan_id).subscribe(res => {
          row.has_subscription = false;
        })
    })
  }
  
  addItemToCart(item: CartItem) {
    this.cartService.addCartItem(item);
  }
  
  addCountToCart(item: CartItem, e: any) {
    this.cartService.addCartItem({...item, count: +e.target.value, update_count: true});
  }
  
  openDialogSubscription(item: CartItem) {
    const dialogRef = this.dialog.open(SubscriptionDialogComponent, {
      position: {
        top: '20px'
      },
      width: '800px',
      data: {
        item
      }
    })
  
    dialogRef.afterClosed().subscribe(result => {
      if (result.formData) {
        this.subscriptionFastspring(result.formData)
      }
    })
    
    
  }
  
  roundCount(item: CartItem, e: any) {
    let count = +e.target.value < item.min_count ? item.min_count : +e.target.value;
    
    count = (item.charge_type === 'requests') ? (Math.ceil(count/1000)*1000) : count;
    this.cartService.addCartItem({...item, count, update_count: true});
  }
  
  remove(item: CartItem, i: number): void {
    this.cartService.removeCartItem(item);
  }
  
  clear(): void {
    this.cartService.clearCart();
  }
  
  checkout(cart: StateTree) {
    if(this.userPaymentSystem === 'fastspring') {
      this.checkoutFastspring(cart);
    } else {
      this.checkoutPaddle(cart);
    }
  }
  
  checkoutPaddle(cart: StateTree) {
    this.loading = true;
    this.cartService.paddleCheckout(cart).subscribe(data => {
      // this.clear();
      document.location.href = data.payment_link;
      this.loading = false;
    }, error => {
      this.loading = false;
      if (error.error.errors) {
        this._snackBar.open(transformHttpError(error.error.errors), 'Close', {duration: 10000, panelClass: ['snack-bar-error']})
      } else {
        this._snackBar.open(transformHttpError(error.error.message), 'Close', {duration: 10000, panelClass: ['snack-bar-error']})
      }
    })
  }
  
  checkoutFastspring(cart: StateTree) {
    this.loading = true;
    this.cartService.fsCheckout(cart).subscribe(data => {
      // this.clear();
      (window as any).fastspring.builder.secure(data.secured_payload, data.secured_key);
      (window as any).fastspring.builder.checkout();
      (window as any).fastspring.builder.availablePaymentMethods = ['paypal'];
      // if(environment.production) {
      //   (window as any).fastspring.builder.payment('paypal');
      // }
      this.loading = false;
    }, error => {
      this.loading = false;
      if (error.error.errors) {
        this._snackBar.open(transformHttpError(error.error.errors), 'Close', {duration: 10000, panelClass: ['snack-bar-error']})
      } else {
        this._snackBar.open(transformHttpError(error.error.message), 'Close', {duration: 10000, panelClass: ['snack-bar-error']})
      }
    })
  }
  
  subscriptionFastspring(item: SubscriptionItem) {
    this.cartService.fsSubscription(item).subscribe(data => {
      (window as any).fastspring.builder.secure(data.secured_payload, data.secured_key);
      (window as any).fastspring.builder.checkout();
      (window as any).fastspring.builder.availablePaymentMethods = ['paypal'];
      // if(environment.production) {
      //   (window as any).fastspring.builder.payment('paypal');
      // }
    }, error => {
      if (error.error.errors) {
        this._snackBar.open(transformHttpError(error.error.errors), 'Close', {duration: 10000, panelClass: ['snack-bar-error']})
      } else {
        this._snackBar.open(transformHttpError(error.error.message), 'Close', {duration: 10000, panelClass: ['snack-bar-error']})
      }
    })
  }
  
  trackByFn(index: any, item: any) {
    return index;
  }
  
  addFastspringScript() {
    const script = document.getElementById('fsc-api');
    if (script) {
      return;
    } else {
      const scriptElement = document.createElement('script');
      const data_access_key = 'IHD_DUPSS5SBFKUUJUZ51W';
      scriptElement.id = 'fsc-api';
      scriptElement.src = 'https://sbl.onfastspring.com/sbl/0.9.5/fastspring-builder.min.js';
      scriptElement.type = 'text/javascript';
      scriptElement.setAttribute('data-storefront', environment.storefrontUrl);
      scriptElement.setAttribute('data-access-key', data_access_key);
      scriptElement.setAttribute('data-popup-closed', 'onPopupClose');
      scriptElement.setAttribute('data-popup-webhook-received', 'popupWebhookReceived');
      scriptElement.setAttribute('data-fsc-action', 'PaypalCheckout');
  
      document.getElementsByTagName('body')[0].appendChild(scriptElement);
    }
  }
  
  @HostListener('window:message', ['$event']) onPayment(event: any) {
    if (event.data.fscPopupMessage) {
      try {
        if (event.data.fscPopupMessage.action && event.data.fscPopupMessage.action === 'close') {
          (window as any).fastspring.builder.reset();
          this.clear();
        }
      } catch (e) {
      }
    }
  }

}

import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {AppState} from "../core/store/app.reducer";
import {RegisterFormModel} from "./models/register-form.model";
import {
  authenticate,
  authenticateOneTime, authenticateThirdParty,
  sendEmailAction,
  sendNewPassAction,
  signUp,
  verifyUserAction
} from "../core/store/user/user.actions";
import {LoginForm} from "./models/login-form.model";
import { ResetPass } from "./models/reset-pass.model"
import { Observable } from "rxjs";
import { AuthApi } from "./api/auth.api";
import {map, take} from "rxjs/operators";
import {isLoading} from "../core/store/user/user.selector";

@Injectable()
export class AuthFacade {

  /**
   * Constructor for AuthFacade
   * @param {Store<AppState>} store 
   * @param {AuthApi} api 
   */
  constructor(private store: Store<AppState>,
              private api: AuthApi) {
  }

  /**
   * Register user
   * @param {RegisterFormModel} registerData
   */
  registerUser(registerData: RegisterFormModel) {
    this.store.dispatch(signUp({...registerData}));
  }

  /**
   * User login
   * @param {LoginForm} loginData
   * @return {void}
   */
  loginUser(loginData: LoginForm): void {
    this.store.dispatch(authenticate(loginData));
  }

  /**
   * User email for reset password
   * @param {any} emailForReset
   * @param {any} recaptcha
   * @return {void}
   */
  sendEmailForReset(emailForReset: string, recaptcha: string): void {
    this.store.dispatch(sendEmailAction({emailForReset, recaptcha}));
  }

  /**
   * Data for reset password
   * @param {ResetPass} newPassData
   * @param {string} recaptcha
   * @return {void} 
   */
  sendNewPass(newPassData: ResetPass, recaptcha: string): void {
    this.store.dispatch(sendNewPassAction({newPassData, recaptcha}));
  }

  /**
   * Request for verify user
   * @param {string} user_id
   * @param {token: string} activation_code
   * @return {Observable<any>}
   */
  verifyUser(user_id: string, activation_code: string): void {
    this.store.dispatch(verifyUserAction({user_id , activation_code}));
  }
  
  getLoadingState(): Observable<boolean> {
    return this.store.pipe(select(isLoading), map(data => !!data));
  }
  
  /**
   * Request for verify user
   * @param {string} uid
   * @param {token: string} onetime_pass
   * @return {Observable<any>}
   */
  loginByOnetimePass(uid: string, onetime_pass: string) {
    this.store.dispatch(authenticateOneTime({uid, onetime_pass}))
  }
  
  goToThirdParty(provider: string): Observable<string> {
    
    return this.api.goToThirdParty(provider).pipe(
        take(1),
        map(res => res.target_url)
    )
  }
  
  loginByThirdParty(provider: string, payload: any) {
    this.store.dispatch(authenticateThirdParty({...payload}))
  }
  
}

import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from "@angular/common";

@Pipe({
  name: 'niceDateFormat'
})
export class NiceDateFormatPipe implements PipeTransform {

  transform(value: string): unknown {
    const dateValue = new Date(value);
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    
    if (dateValue.getFullYear() == today.getFullYear() && dateValue.getMonth() == today.getMonth() && dateValue.getDate() == today.getDate())
      return "Today";
    else if (dateValue.getFullYear() == yesterday.getFullYear() && dateValue.getMonth() == yesterday.getMonth() && dateValue.getDate() == yesterday.getDate())
      return "Yesterday";
    else{
      return (new DatePipe("en-US")).transform(value, 'MMM d');
    }
  }

}

<div class="form" >
	<div class="row align-items-end">
		<div class="col-12">
			<h6 class="mb-2">How could we contact you?</h6>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<div class="input-group">
					<mat-form-field  appearance="outline"
					>
						<mat-label>Select Messenger
						</mat-label>
						<mat-select [(value)]="selectedMessengerType" (valueChange)="changeMessenger(selectedMessengerType)">
							<mat-option [value]="option.key" *ngFor="let option of messengerTypes">{{ option.label }}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="col-md-6" *ngIf="selectedMessengerType">
			<div class="form-group">
					<label>Enter the account <span class="red">*</span></label>
					<input [formControl]="messengerValueCtrl" type="text" class="form-control" >
				<mat-error *ngIf="messengerValueCtrl?.hasError('pattern') && messengerValueCtrl?.touched">
					Value is <strong>not valid</strong>
				</mat-error>
				<mat-error *ngIf="messengerValueCtrl?.hasError('minLength') && messengerValueCtrl?.touched">
					Min length equals <strong>5</strong>
				</mat-error>
			</div>
		</div>
	</div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {ErrorVerifyComponent} from './containers/sign-in/error-verify/error-verify-component.component';
import {SuccessVerifyComponent} from './containers/sign-in/success-verify/success-verify-component.component'

import {containers} from "./containers";
import {AuthFacade} from "./auth.facade";
import {RequestInterceptorService} from "../core/services/app.interceptor";
import {AuthRoutingModule} from "./auth-routing.module";
import {RecaptchaModule} from 'ng-recaptcha';
import {AlertModule} from "../core/modules/parts/alert/alert.module";
import { EmailPasswordComponent } from './containers/sign-up/steps/email-password/email-password.component';
import { PersonalInfoComponent } from './containers/sign-up/steps/personal-info/personal-info.component';
import { ContactInfoComponent } from './containers/sign-up/steps/contact-info/contact-info.component';
import { UseCaseComponent } from './containers/sign-up/steps/use-case/use-case.component';
import {MatSelectModule} from "@angular/material/select";
import { VerifyNoticeComponent } from './containers/sign-in/verify-notice/verify-notice.component';
import { FingerprintjsProAngularModule} from '@fingerprintjs/fingerprintjs-pro-angular';
import {environment} from "../../environments/environment";
import {MatIconModule} from "@angular/material/icon";
import { SignUpNewComponent } from './containers/sign-up-new/sign-up-new.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {LanguageSelectorModule} from "../core/modules/parts/language-selector/language-selector.module";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    ...containers,
    ErrorVerifyComponent,
    SuccessVerifyComponent,
    EmailPasswordComponent,
    PersonalInfoComponent,
    ContactInfoComponent,
    UseCaseComponent,
    VerifyNoticeComponent,
    SignUpNewComponent
    // EmailConfirmComponent,
  ],
	imports: [
		CommonModule,
		HttpClientModule,
		ReactiveFormsModule,
		AuthRoutingModule,
		MatInputModule,
		MatButtonModule,
		MatSnackBarModule,
		MatDialogModule,
		RecaptchaModule,
		AlertModule,
		MatSelectModule,
		LanguageSelectorModule,
		FingerprintjsProAngularModule.forRoot({
		loadOptions: {
			apiKey: environment.fingerprintPublicKey
		}
		}),
		MatIconModule,
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			},
			isolate: false
		})
	],
  providers: [
    AuthFacade,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    }
  ]
})
export class AuthModule { }

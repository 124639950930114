import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[statusCodeTag]'
})
export class StatusCodeTagDirective implements OnInit {

  @Input() statusCodeTag: number = 0;

  constructor(private renderer: Renderer2,
              private elementRef: ElementRef) {}

  ngOnInit() {
    if (this.statusCodeTag) {
      this.elementRef.nativeElement.style.padding = '1px 3px';
      this.elementRef.nativeElement.style.borderRadius = '6px';
      this.elementRef.nativeElement.style.border = '1px solid #b6b6b6';
      if (this.statusCodeTag < 300)
        this.elementRef.nativeElement.style.backgroundColor = '#93ff93';
      if (this.statusCodeTag > 299 && this)
        this.elementRef.nativeElement.style.backgroundColor = '#ff8686';
    }
  }

}

<div [ngClass]="'language-selector-'+size" *ngIf="size === 'min'; else inMenuBlock" >
	<button class="language-button" (click)="toggleDropdown()">
		<i class="icon-language mr-2"></i> {{ currentLanguage.key }}</button>
	<div *ngIf="isDropdownOpen" class="language-dropdown">
		<span *ngFor="let l of languagesList" (click)="changeLanguage(l.key); closeDropdown()">{{l.value}}</span>
	</div>
</div>

<ng-template #inMenuBlock>
	<div id='menu-language-selector' class="language-selector-full">
		<li class="dropdown-submenu">
			<span id="menu-language-selector-main" class="dropdown-toggle" (click)="toggleDropdown()">
				<i class="icon-language mr-2"></i> {{ currentLanguage.value }}
				<i class="mr-2" [ngClass]="isDropdownOpen? 'dx-icon-chevrondown' : 'dx-icon-chevronright'"></i>
			</span>
			<ul id="menu-language-selector-items" class="dropdown-menu" *ngIf="isDropdownOpen">
				<li *ngFor="let l of languagesList"><span class="dropdown-item" (click)="changeLanguage(l.key)">{{l.value}}</span></li>
			</ul>
		</li>
	</div>
</ng-template>

<h6 class="text-center my-3">{{'Enter your new password in the fields below' | translate}}</h6>

<form class="w-100 my-3" [formGroup]="form">
  <div class="form-group">
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text"><img src="./assets/images/lock.png" alt="" class="img-responsive"></div>
      </div>
      <input formControlName="password" type="password" class="form-control" id="resetPasswordGroup" [placeholder]="'Password' | translate">
    </div>
    <mat-error *ngIf="password?.hasError('minlength')">
      {{'Min length of password' | translate}} <strong>8</strong>
    </mat-error>
    <mat-error *ngIf="password?.hasError('required') && password?.touched">
      {{'Password is required' | translate}}
    </mat-error>
  </div>
  <div class="form-group">
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text"><img src="./assets/images/lock.png" alt="" class="img-responsive"></div>
      </div>
      <input formControlName="passwordConfirm" type="password" class="form-control" id="resetPasswordConfirmGroup" [placeholder]="'Repeat password' | translate">
    </div>
    <mat-error *ngIf="passwordConfirm?.hasError('required')">
      {{'Password is required' | translate}}
    </mat-error>
    <mat-error *ngIf="passwordConfirm?.hasError('matching') && passwordConfirm?.touched">
      {{'Password must matches' | translate}}
    </mat-error>
  </div>
  <button type="submit" class="btn btn-default w-100 btn-sm" [disabled]="form.invalid" (click)="captchaRef.execute()">{{'Submit' | translate}}</button>
</form>

<p class="text-center">{{'Back to' | translate}} <a routerLink="/sign-in">{{'Sign In' | translate}}</a></p>

<re-captcha size="invisible" #captchaRef (resolved)="$event && sendNewPass($event)"
            [siteKey]="SITE_ID_NG">
</re-captcha>

<h4 mat-dialog-title>Thank you for signing up with us!
</h4>
<p mat-dialog-content>
	We are thrilled to have you join our community.
	To complete the registration process, please check your email and click on the verification link we have sent to you.
	This will confirm your account and give you full access to all the features on our platform. <br> <br>
	If you don’t see the email in your inbox, please check your spam or junk folder. If you still don’t see it, please reach out to our customer support team for assistance via the online chat or email <a href="mailto:info@dataimpulse.com">info@dataimpulse.com</a>
	<br><br>
	Thank you for choosing to be a part of our community. We look forward to seeing you around!
</p>
<div mat-dialog-actions>
	<button mat-button mat-dialog-close cdkfocusinitial>Close</button>
</div>

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PlansList} from "../../../core/models/plans-list.model";
import {CartItem} from "../../../core/models/cart-item.model";
import {INewOrderPayload} from "../../../core/models/order.model";
import {IStripeCustomerInfo, IUserLocationInfo, IUserPaymentSystems, IUserPlan} from "../../../core/models/user.model";

@Injectable()
export class PlansApi {
	
	constructor(private httpClient: HttpClient) {
	
	}
	
	getAllPlans(): Observable<{data: IUserPlan[]}> {
		return this.httpClient.get<{data: any}>('monetizer_api/api/plans/get_plans');
	}
	
	getIpListForPlan(plan_id: number): Observable<{ip_list: string[]}> {
		const params = {
			plan_id
		}
		return this.httpClient.get<{ip_list: string[]}>('monetizer_api/api/plans/ip_list/get', {params});
	}

	addIpToPlan(plan_id: number, ip: string): Observable<{ip_list: string[]}> {
		const postData = {
			plan_id,
			ip
		}
		return this.httpClient.post<{ip_list: string[]}>('monetizer_api/api/plans/ip_list/set', postData);
	}

	removeIpFromPlan(plan_id: number, ip: string): Observable<{ip_list: string[]}> {
		const postData = {
			plan_id,
			ip
		}
		return this.httpClient.post<{ip_list: string[]}>('monetizer_api/api/plans/ip_list/delete', postData);
	}
	
	fsPlansCheckout(total_amount: number, items: CartItem[], ga_cid: string, session_id: string): Observable<{secured_payload: string, secured_key: string}> {
		const postData = {
			total_amount,
			items,
			ga_cid,
			session_id
		}
		return this.httpClient.post<{secured_payload: string, secured_key: string}>('monetizer_api/api/payments/fastspring/get_payment_payload', postData);
	}
	
	// strpPlansCheckout(payment_method: string, total_amount: number, items: CartItem[], ga_cid: string, session_id: string): Observable<{data: {client_secret: string}}> {
	// 	const postData = {
	// 		payment_method,
	// 		total_amount,
	// 		items,
	// 		ga_cid,
	// 		session_id
	// 	}
	// 	return this.httpClient.post<{data: {client_secret: string}}>('monetizer_api/api/payments/stripe/get_payment_payload', postData);
	// }
	
	strpPlansCheckout(payment_method: string, total_amount: number, items: CartItem[], ga_cid: string, session_id: string): Observable<{client_secret: string}> {
		const postData = {
			payment_method,
			total_amount,
			items,
			ga_cid,
			session_id
		}
		return this.httpClient.post<{client_secret: string}>('monetizer_api/api/payments/stripe/get_payment_payload', postData);
	}
	
	strpPlansSubscriptionCheckout(payment_method: string, plan_id: number, amount: number, traffic_count: number, count_limit: number, ga_cid: string, session_id: string): Observable<{client_secret: string}> {
		const postData = {
			payment_method,
			plan_id,
			amount,
			traffic_count,
			count_limit,
			ga_cid,
			session_id
		}
		return this.httpClient.post<{client_secret: string}>('monetizer_api/api/payments/stripe/get_subscription_payload', postData);
	}
	
	npPlansCheckout(total_amount: number, items: CartItem[], ga_cid: string, session_id: string): Observable<{payment_link: string}> {
		const postData = {
			total_amount,
			items,
			ga_cid,
			session_id
		}
		return this.httpClient.post<{payment_link: string}>('monetizer_api/api/payments/np/get_payment_payload', postData);
	}
	
	cmPlansCheckout(total_amount: number, items: CartItem[], ga_cid: string, session_id: string): Observable<{payment_link: string}> {
		const postData = {
			total_amount,
			items,
			ga_cid,
			session_id
		}
		return this.httpClient.post<{payment_link: string}>('monetizer_api/api/payments/cryptomus/get_payment_payload', postData);
	}
	
	paddlePlansCheckout(total_amount: number, items: CartItem[], ga_cid: string, session_id: string): Observable<{transaction_id: string, customer_id: string}> {
		const postData = {
			total_amount,
			items,
			ga_cid,
			session_id
		}
		// return this.httpClient.post<{payment_link: string}>('monetizer_api/api/payments/paddle/v1/get_payment_payload', postData);
		return this.httpClient.post<{transaction_id: string, customer_id: string}>('monetizer_api/api/payments/paddle/get_payment_payload', postData);
	}
	
	pssnPlansCheckout(total_amount: number, items: CartItem[], ga_cid: string, session_id: string): Observable<{payment_link: string}> {
		const postData = {
			total_amount,
			items,
			ga_cid,
			session_id
		}
		return this.httpClient.post<{payment_link: string}>('monetizer_api/api/payments/payssion/get_payment_payload', postData);
	}
	
	// newPlanFSCheckout(payload: INewOrderPayload): Observable<{secured_payload: string, secured_key: string}> {
	// 	const postData = {...payload}
	// 	return this.httpClient.post<{secured_payload: string, secured_key: string}>('monetizer_api/api/plans/add_plan', postData);
	// }
	
	newPlanFSCheckout(payload: INewOrderPayload): Observable<{secured_payload: string, secured_key: string}> {
		const postData = {...payload}
		return this.httpClient.post<{secured_payload: string, secured_key: string}>('monetizer_api/api/plans/fastspring/add_plan', postData);
	}
	
	newPlanNPCheckout(payload: INewOrderPayload): Observable<{payment_link: string}> {
		const postData = {...payload}
		return this.httpClient.post<{payment_link: string}>('monetizer_api/api/plans/np/add_plan', postData);
	}
	
	newPlanCmCheckout(payload: INewOrderPayload): Observable<{payment_link: string}> {
		const postData = {...payload}
		return this.httpClient.post<{payment_link: string}>('monetizer_api/api/plans/cryptomus/add_plan', postData);
	}
	
	newPlanPaddleCheckout(payload: INewOrderPayload): Observable<{transaction_id: string, customer_id: string}> {
		const postData = {...payload}
		return this.httpClient.post<{transaction_id: string, customer_id: string}>('monetizer_api/api/plans/paddle/add_plan', postData);
	}
	
	newPlanPssnCheckout(payload: INewOrderPayload): Observable<{payment_link: string}> {
		const postData = {...payload}
		return this.httpClient.post<{payment_link: string}>('monetizer_api/api/plans/payssion/add_plan', postData);
	}
	
	newPlanStrpCheckout(payload: INewOrderPayload, paymentMethod: string): Observable<{client_secret: string}> {
		const postData = {...payload, payment_method: paymentMethod}
		return this.httpClient.post<{client_secret: string}>('monetizer_api/api/plans/stripe/add_plan', postData);
	}
	
	newPlanStrpCheckoutSession(payload: INewOrderPayload, paymentMethod: string): Observable<any> {
		const postData = {...payload, payment_method: paymentMethod}
		return this.httpClient.post<any>('monetizer_api/api/plans/stripe_checkout/add_plan', postData);
	}
	
	getMinPaymentSum(): Observable<{result: number}> {
		return this.httpClient.get<{result: number}>('monetizer_api/api/payments/get_min_payment_sum');
	}
	
	getPoolMinPaymentSum(): Observable<{result: {residental: number, datacenter: number, mobile: number}}> {
		return this.httpClient.get<{result: {residental: number, datacenter: number, mobile: number}}>('monetizer_api/api/payments/get_pool_min_payment_sum');
	}
	
	getAvailablePaymentSystems(): Observable<{result: IUserPaymentSystems}> {
		return this.httpClient.get<{result: IUserPaymentSystems}>('monetizer_api/api/payments/get_available_ps');
	}
	
	getUserLocation(): Observable<{result: IUserLocationInfo}> {
		return this.httpClient.get<{result: IUserLocationInfo}>('monetizer_api/api/payments/get_location');
	}
	
	cancelPlanSubscription(plan_id: number): Observable<any> {
		return this.httpClient.post<any>('monetizer_api/api/payments/cancel_subscription', {plan_id})
	}
	
	getStripeCustomerInfo(): Observable<{ data: IStripeCustomerInfo }> {
		return this.httpClient.get<{ data: IStripeCustomerInfo }>('monetizer_api/api/payments/stripe/customer_info')
	}
	
	checkOrderStatus(orderId: string, paymentSystem: string): Observable<any> {
		let postData = new FormData();
		postData.append("order_id", orderId);
		postData.append("payment_system", paymentSystem);
		
		return this.httpClient.post<any>('monetizer_api/api/payments/check_order_status', postData);
	}
	
	setOrderGaStatus(orderId: string): Observable<any> {
		let postData = new FormData();
		postData.append("order_id", orderId);
		
		return this.httpClient.post<any>('monetizer_api/api/payments/set_order_ga_status', postData);
	}
	
	updateFbAnalyticsParams(fbp: string, fbc: string): Observable<any> {
		const postData = {
			fbp,
			fbc
		}
		
		return this.httpClient.post<any>('monetizer_api/api/payments/update_analytics_params', postData);
	}
	
	
 }

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { fadeInDownOnEnterAnimation} from "angular-animations";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {getUserData, isUserAuthenticated} from "../../store/user/user.selector";
import {AppState} from "../../store/app.reducer";
import {authenticate, initPlans, signOut} from "../../store/user/user.actions";
import {setDomainData} from "../../store/domain/domain.actions";
import {User} from "../../models/user.model";
import {CartService, StateTree} from "../cart/cart.service";
import {HeaderService} from "./header.service";
import {environment} from "../../../../environments/environment";
import {isDateBetweenTwoDates} from "../../utils/utils";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    fadeInDownOnEnterAnimation({anchor: 'enter', duration: 500})
  ]
})
export class HeaderComponent implements OnInit {
  
  /**
   * Emmit event for left menu toggle
   * @type {EventEmitter<void>}
   */
  @Output() menuToggle: EventEmitter<void> = new EventEmitter<void>()

  isUserAuthenticated$: Observable<boolean>;
  
  userData$: Observable<User | undefined>;
  // cartState$: Observable<StateTree>;
  
  showIntercom: boolean = true;
  
  isHoliday: boolean = false;

  constructor(private store: Store<AppState>, private headerService: HeaderService) {
    this.isUserAuthenticated$ = store.select(isUserAuthenticated)
    this.userData$ = store.select(getUserData);
    this.userData$.subscribe((user => {
      if(user) {
        this.showIntercom = user.show_intercom;
        if (this.showIntercom) {
          (window as any).Intercom('boot', {
            app_id: 'yhwl2kwv',
            user_hash: user.intercom_hash,
            vertical_padding: 100,
            created_at: user.created_at,
            name: user.name,
            user_name: user.name,
            email: user.email,
            domain: environment.appUrl,
            hide_default_launcher: false,
            link_auth_di: 'https://'+environment.appUrl+'/login_onetime_admin/' + user.id + '/' + user.intercom_token,
          });
        } else if (!this.showIntercom) {
          (window as any).Intercom('shutdown');
        }
      }
    }));
    // this.cartState$ = this.cartService.state$
    // this.cartState$.subscribe()
    this.store.dispatch(initPlans())
  }

  ngOnInit(): void {
    
    this.isHoliday = isDateBetweenTwoDates(
      new Date(),
      new Date('2023-11-24T00:00:00+01:00'),
      new Date('2023-11-26T24:00:00+01:00')
    )
  }

  login() {
    // this.store.dispatch(authenticate())
  }

  logout() {
    this.store.dispatch(signOut())
  }

  saveDomainDataToState(domainData: {domain: string; domainTypeId: number}) {
    this.store.dispatch(setDomainData(domainData));
  }
  
  /**
   * Left menu toggle handler
   * @return {void}
   */
  menuToggleHandler(): void {
    this.menuToggle.emit()
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {INotificationItem, NotificationsService} from "../notifications.service";

@Component({
  selector: 'app-notifications-item',
  templateUrl: './notifications-item.component.html',
  styleUrls: ['./notifications-item.component.scss']
})
export class NotificationsItemComponent implements OnInit {

  @Input() notificationItem!: INotificationItem;
  
  expand: boolean = false;
  constructor(private service: NotificationsService) { }

  ngOnInit(): void {
  }
  
  readNotification() {
    this.expand = !this.expand;
    if (this.notificationItem.read) return;
    this.notificationItem.read = true;
    this.service.saveNotification(this.notificationItem.id);
  }

}

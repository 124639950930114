<div class="datepicker">
	<mat-form-field class="datetime-form-field select-date" appearance="outline"
	>
		<mat-label>{{'Select Dates' | translate}}</mat-label>
		<mat-date-range-input
			[formGroup]="selectDateGroup"
			[rangePicker]="campaignTwoPicker"
			[comparisonStart]="selectDateGroup.value.start"
			[comparisonEnd]="selectDateGroup.value.end"
			[min]="minDate"
			[max]="maxDate">
			<input matStartDate [placeholder]="'Start date' | translate" formControlName="start" >
			<input matEndDate [placeholder]="'End date' | translate" formControlName="end" (dateChange)="onChangeRange()">
		</mat-date-range-input>
		<mat-datepicker-toggle matSuffix [for]="campaignTwoPicker"></mat-datepicker-toggle>
		<mat-date-range-picker #campaignTwoPicker></mat-date-range-picker>
	</mat-form-field>
</div>

<div class="notifications">
	<div class="notifications_bell" (click)="open()">
		<mat-icon [class.animate]="notificationsCount > 0">notifications</mat-icon>
		<span class="notifications_count" *ngIf="notificationsCount">
			{{ notificationsCount > 9 ? '9+' : notificationsCount}}
		</span>
	</div>
	
	<app-notifications-modal *ngIf="opened" [notifications]="notifications" ></app-notifications-modal>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from "@angular/router";
import {LanguageSelectorComponent} from "./language-selector.component";



@NgModule({
	declarations: [
		LanguageSelectorComponent
	],
	imports: [
		CommonModule,
		RouterModule
	],
	exports: [LanguageSelectorComponent]
})
export class LanguageSelectorModule { }

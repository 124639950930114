import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  cancelBtnText: string = 'Cancel';
  
  confirmBtnText: string = 'Confirm';
  
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: {title: string, cancel_btn_text: string, confirm_btn_text: string}
  ) {
    if(data.cancel_btn_text)
      this.cancelBtnText = data.cancel_btn_text;
    if(data.confirm_btn_text)
      this.confirmBtnText = data.confirm_btn_text;
  }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import { Observable, fromEventPattern } from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {AccountFacade} from "../../account/account.facade";

declare global {
	interface Window {
		dataLayer: any[];
	}
}

@Injectable({
	providedIn: 'root',
})
export class DataLayerService {
	constructor(private accountFacade: AccountFacade) {}
	
	public watchDataLayer(): Observable<any> {
		return fromEventPattern(
			(handler) => {
				const originalPush = window.dataLayer.push;
				window.dataLayer.push = function () {
					// @ts-ignore
					originalPush.apply(this, arguments);
					handler(arguments[0]);
					// @ts-ignore
					return Array.prototype.push.apply(this, arguments);
				};
			}
		);
	}
	
	setConsentData(cookieData: string): Observable<any> {
		return this.accountFacade.setConsentData(cookieData);
	}
}

import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {StoreModule} from "@ngrx/store";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {environment} from "../environments/environment";
import {reducers} from "./core/store/app.reducer";
import {HeaderModule} from "./core/modules/header/header.module";
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {LeftMenuModule} from "./core/modules/left-menu/left-menu.module";
import {EffectsModule} from "@ngrx/effects";
import {UserEffects} from "./core/store/user/user.effect";
import {AuthModule} from './auth/auth.module';
import {AuthGuard} from "./auth/guards/auth.guard";
import {OnlyGuestGuard} from "./auth/guards/only-guest.guard";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {AuthApi} from "./auth/api/auth.api";
import {AlertModule} from "./core/modules/parts/alert/alert.module";
import {AccountApi} from "./account/api/account.api";
import {HIGHLIGHT_OPTIONS, HighlightModule, HighlightOptions} from 'ngx-highlightjs';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {LanguageSelectorModule} from "./core/modules/parts/language-selector/language-selector.module";
import {ViewEffect} from "./core/store/view/view.effect";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {CustomPaginator} from "./core/utils/utils";


export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HighlightModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    HeaderModule,
    LeftMenuModule,
    AuthModule,
    AppRoutingModule,
    HttpClientModule,
    AlertModule,
    LanguageSelectorModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([UserEffects, ViewEffect]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [AuthApi, AuthGuard, AccountApi,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: <HighlightOptions> {
        fullLibraryLoader: () => import('highlight.js'),
      }
    },
    // { provide: MatPaginatorIntl, useValue: CustomPaginator() }
    {
      provide: MatPaginatorIntl,
      useFactory: (translateService: TranslateService) => CustomPaginator(translateService),
      deps: [TranslateService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

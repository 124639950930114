import {createAction, props} from '@ngrx/store';
import {IUserPlan, User} from "../../models/user.model";
import {ResetPass} from "../../../auth/models/reset-pass.model"
import {SocialPayload} from "../../models/social-payload.model";
import {RegisterFormModel} from "../../../auth/models/register-form.model";


export const authenticate = createAction(
  '[User authenticate]',
  props<{email: string, password: string, recaptcha: string, visitor_id: string}>()
);

export const authenticateOneTime = createAction(
    '[User authenticate by onetime pass]',
    props<{uid: string, onetime_pass: string}>()
);

export const authenticateThirdParty = createAction(
    '[User authenticate by social]',
    props<{provider: string, code: string, scope: string, authuser: string, session_state: string, prompt: string, affiliate_id: string | null, ga_cid: string | null}>()
);

export const authenticateSuccess = createAction(
  '[User authenticate success]',
  props<{token: string, user: User}>()
);

export const authenticateThirdPartySuccess = createAction(
    '[User social authenticate success]',
    props<{token: string, user: User}>()
);

export const authenticateError = createAction(
  '[User authenticate error]',
  props<{message: string}>()
);

export const signOut = createAction('[User sign out]');

export const signOutSuccess = createAction('[User sign out success]');

export const signOutError = createAction('[User sign out error]');

export const signUp = createAction(
  '[User sign up]',
  props<RegisterFormModel>()
  );

export const signUpSuccess = createAction(
  '[User sign up success]',
  props<{token: string, user: User}>()
);

export const signUpError = createAction(
  '[User sign up error]',
  props<{message: string}>()
);

export const sendEmailAction = createAction(
  '[User send email]',
  props<{emailForReset: string, recaptcha: string}>()
);

export const sendEmailActionSuccess = createAction(
  '[User send email success]',
  props<{data: string}>()
);

export const sendEmailActionError = createAction(
  '[User send email error]',
  props<{message: string}>()
);

export const sendNewPassAction = createAction(
  '[User send new password]',
  props<{newPassData: ResetPass, recaptcha: string}>()
);

export const sendNewPassActionSuccess = createAction(
  '[User send new password success]',
  props<{data: any}>()
);

export const sendNewPassActionError = createAction(
  '[User send new password error]',
  props<{message: string}>()
);

export const verifyUserAction = createAction(
  '[Verify user]',
  props<{user_id: string, activation_code: string}>()
)

export const verifyUserActionSuccess = createAction(
  '[Verify user success]',
  props<{data: any}>()
)

export const verifyUserActionError = createAction(
  '[Verify user error]',
  props<{data: any}>()
)

export const updateUserInfo = createAction(
    '[Update User Info]',
    props<{name: string}>()
)

export const updateUserLanguage = createAction(
    '[Update User Info]',
    props<{language: string}>()
)

export const updateUserInfoSuccess = createAction(
    '[Update User Info Success]',
    props<{user: User}>()
)

export const updateUserInfoError = createAction(
    '[Update User Info Error]',
    props<{message: string}>()
)


export const initPlans = createAction(
  '[Init Plans]'
)

export const initPlansSuccess = createAction(
  '[Init Plans Success]',
  props<{user: User}>()
)

export const initPlansError = createAction(
  '[Init Plans Error]',
  props<{message: string}>()
)

export const updateUserPlans = createAction(
    '[Update User Plans]',
    props<{language: string}>()
)

export const loadUserInfo = createAction(
    '[Load user info]'
)

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from "./auth/guards/auth.guard";
import {NotFoundComponent} from "./core/modules/not-found/not-found.component";
import {CartComponent} from "./core/modules/cart/cart.component";
import {HasPlansGuard} from "./auth/guards/has-plans.guard";

const routes: Routes = [{
  path: 'dashboard',
  canActivate: [AuthGuard, HasPlansGuard],
  loadChildren:() => import('./products/dashboard/dashboard.module').then(m => m.DashboardModule)
}, {
  path: 'plans',
  canActivate: [AuthGuard],
  loadChildren:() => import('./products/plans/plans.module').then(m => m.PlansModule)
}, {
  path: 'reseller',
  canActivate: [AuthGuard],
  loadChildren:() => import('./products/reseller/reseller.module').then(m => m.ResellerModule)
}, {
  path: 'affiliate',
  canActivate: [AuthGuard],
  loadChildren:() => import('./products/affiliate/affiliate.module').then(m => m.AffiliateModule)
}, {
  path: 'docs',
  canActivate: [AuthGuard],
  loadChildren:() => import('./products/docs/docs.module').then(m => m.DocsModule)
}, {
  path: 'locations',
  canActivate: [AuthGuard],
  loadChildren:() => import('./products/locations/locations.module').then(m => m.LocationsModule)
}, {
  path: 'products',
  canActivate: [AuthGuard, HasPlansGuard],
  loadChildren:() => import('./products/products/products.module').then(m => m.ProductsModule)
}, {
  path: 'cart',
  canActivate: [AuthGuard, HasPlansGuard],
  component: CartComponent
}, {
  path: '',
  redirectTo: 'dashboard',
  pathMatch: 'full'
}, {
  path: 'account',
  canActivate: [AuthGuard],
  loadChildren:() => import('./account/account.module').then(m => m.AccountModule)
}, {
  path: 'no-plans',
  canActivate: [AuthGuard],
  loadChildren:() => import('./no-plans/no-plans.module').then(m => m.NoPlansModule)
}, {
  path: '**',
  redirectTo: '404'
}, {
  path: '404',
  component: NotFoundComponent
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

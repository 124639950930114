import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, exhaustMap, map, tap, timeout} from "rxjs/operators";
import {of} from "rxjs";
import {transformHttpError} from "../../utils/utils";
import {AccountApi} from "../../../account/api/account.api";
import {setLanguage, setLanguageError, setLanguageSuccess} from "./view.actions";
import {Store} from "@ngrx/store";
import {AppState} from "../app.reducer";
import {isUserAuthenticated} from "../user/user.selector";
import {updateUserInfoSuccess} from "../user/user.actions";

@Injectable()
export class ViewEffect {

    setLanguage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setLanguage),
            exhaustMap(payload => {
                if(this.authenticated) {
                    return this.accountApi.updateLanguage(payload.language)
                    .pipe(
                        map(userData => {
                            let user = JSON.parse(<string>localStorage.getItem('currentUser'));
                            user.language = userData.data.user.language;
                            return updateUserInfoSuccess({user});
                            // return setLanguageSuccess();
                        }),
                        catchError((error) => {
                            return of(setLanguageError());
                        })
                    )
                } else {
                    return of(setLanguageSuccess());
                }
            }
            )
        )
    );
    
  authenticated: boolean = false;
  
  constructor(
    private actions$: Actions,
    private accountApi: AccountApi,
    private store: Store<AppState>
  ) {
      this.store.select(isUserAuthenticated).subscribe(auth => {
          this.authenticated = auth;
      })
  }
  
}

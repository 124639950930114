<app-language-selector size="min"></app-language-selector>
<h2 class="text-center my-3">{{'Sign Up' | translate}}</h2>
<div class="text-center">{{'Already have an account?' | translate}} <a routerLink="/sign-in">{{'Sign In' | translate}}</a></div>
<hr>
<form class="form" [formGroup]="form" autocomplete="off">
	<div class="form-row mb-2">
		<div class="col-md form-group">
			<label>{{'Name' | translate}} <span class="red">*</span></label>
			<input formControlName="name" type="text" class="form-control form-control-sm" id="signUpNameGroup" autocomplete="off" placeholder="{{'Your Name' | translate}}">
			<mat-error *ngIf="getFormControl('name')?.hasError('required') && getFormControl('name')?.touched">
				{{'Name is' | translate}} <strong>{{'required' | translate}}</strong>
			</mat-error>
		</div>
		<div class="col-md form-group">
			<label>{{'Email address' | translate}} <span class="red">*</span></label>
			<input formControlName="email" type="email" class="form-control form-control-sm" id="loginEmailGroup" autocomplete="new-email" placeholder="example@gmail.com">
			<mat-error *ngIf="getFormControl('email')?.hasError('email') && !getFormControl('email')?.hasError('required')">
				{{'Please enter a valid email address' | translate}}
			</mat-error>
			<mat-error *ngIf="getFormControl('email')?.hasError('required') && getFormControl('email')?.touched">
				{{'Email is' | translate}} <strong>{{'required' | translate}}</strong>
			</mat-error>
		</div>
	</div>
	<div class="form-row mb-2">
		<div class="form-group col-md">
			<label>{{'Password' | translate}} <span class="red">*</span></label>
			<div class="input-group input-group-sm">
				<input formControlName="password" [attr.type]="isShowPass ? 'text' : 'password'" class="form-control form-control-sm" id="signUpPasswordGroup" autocomplete="new-password">
				<div class="input-group-append">
					<div class="input-group-text cursor-pointer" (click)="showPass($event)">
						<mat-icon [inline]="true">{{ isShowPass ? 'visibility': 'visibility_off'}}</mat-icon>
					</div>
				</div>
			</div>
			
			<mat-error *ngIf="getFormControl('password')?.hasError('required') && getFormControl('password')?.touched">
				{{'Password is' | translate}} <strong>{{'required' | translate}}</strong>
			</mat-error>
			<mat-error *ngIf="getFormControl('password')?.hasError('minlength')">
				{{'Min length of password' | translate}} <strong>8</strong>
			</mat-error>
		</div>
		<div class="form-group col-md">
			<label>{{'Repeat Password' | translate}} <span class="red">*</span></label>
			<input formControlName="passwordConfirm" type="password" class="form-control form-control-sm" id="signUpPasswordConfirmGroup" autocomplete="new-password">
			<mat-error *ngIf="getFormControl('passwordConfirm')?.hasError('matching') && getFormControl('passwordConfirm')?.touched && getFormControl('passwordConfirm')?.touched">
				{{'Confirm password must' | translate}} <strong>{{'matches' | translate}}</strong>
			</mat-error>
		</div>
	</div>
	<div class="form-group">
		<label>{{'Company' | translate}}</label>
		<input formControlName="company" type="text" class="form-control form-control-sm" id="signUpCompanyGroup" autocomplete="off">
		<mat-error *ngIf="getFormControl('company')?.hasError('required') && getFormControl('company')?.touched">
			{{'Company is' | translate}} <strong>{{'required'}}</strong>
		</mat-error>
	</div>
<!--	<div class="form-group">-->
<!--		<label>Phone</label>-->
<!--		<input formControlName="phone" type="text" class="form-control form-control-sm" id="signUpPhoneGroup" autocomplete="off">-->
<!--		<mat-error *ngIf="getFormControl('phone')?.hasError('required') && getFormControl('phone')?.touched">-->
<!--			Phone is <strong>required</strong>-->
<!--		</mat-error>-->
<!--	</div>-->
	<div class="form-group mb-0">
		<label class="mb-0">{{'How could we contact you?' | translate}} <span class="red">*</span></label>
	</div>
	<div class="form-row align-items-start">
		<div class="col-md-12 form-group mt-1">
			<div class="input-group">
				<mat-form-field  appearance="outline"
				>
					<mat-label>{{'Select Messenger' | translate}}
					</mat-label>
					<mat-select [(value)]="selectedMessengerType" (valueChange)="changeMessenger(selectedMessengerType)" formControlName="messenger_type">
						<mat-option [value]="option.key" *ngFor="let option of messengerTypes">{{ option.label }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="col-md-12 form-group mt-1" *ngIf="showMessengerField">
			<input formControlName="messenger_account" type="text" class="form-control form-control-sm" [placeholder]="'Enter the account' | translate" (input)="messengerChange($event)">
			<mat-error *ngIf="getFormControl('messenger_account')?.hasError('required') && getFormControl('messenger_account')?.touched">
				{{'Messenger Account is required' | translate}}
			</mat-error>
			<mat-error *ngIf="getFormControl('messenger_account')?.hasError('pattern') && getFormControl('messenger_account')?.touched">
				{{'Value is not valid' | translate}}
			</mat-error>
			<mat-error *ngIf="getFormControl('messenger_account')?.hasError('minLength') && getFormControl('messenger_account')?.touched">
				{{'Min length equals' | translate}} <strong>5</strong>
			</mat-error>
		</div>
		
	</div>
	<div class="form-group mb-0 mt-3">
		<label class="mb-0"> {{'Use Case' | translate}} <span class="red">*</span></label>
	</div>
	<div class="form-row align-items-start">
		<div class="col-md-12">
			<div class="form-group mt-1">
				<div class="input-group">
					<mat-form-field  appearance="outline">
						<mat-label>{{'Use Case' | translate}}
						</mat-label>
						<mat-select [(value)]="selectedUseCase" (valueChange)="useCaseChange(selectedUseCase)" formControlName="use_case">
							<mat-option [value]="option" *ngFor="let option of useCases">{{ option }}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="col-md-12" *ngIf="showOtherUseCaseField$.value">
			<div class="form-group mt-1">
				<input formControlName="use_case_custom" type="text" class="form-control form-control-sm" placeholder="Enter your use case">
				<mat-error *ngIf="getFormControl('use_case_custom')?.hasError('required') && getFormControl('use_case_custom')?.touched">
					{{'This field is required' | translate}}
				</mat-error>
				<mat-error *ngIf="getFormControl('use_case_custom')?.hasError('minLength') && getFormControl('use_case_custom')?.touched">
					{{'Min length equals' | translate}} <strong>5</strong>
				</mat-error>
			</div>
		</div>
		
	</div>
	<div class="mt-4">
		<label class="form-check form-check-inline">
			<input type="checkbox" [formControl]="acceptTerms" class="form-check-input">
			<span class="form-check-label fw-semibold text-gray-700 fs-base ms-1" [innerHtml]="'SIGN_UP_TERMS_PRIVACY_CONFIRMATION' | translate"></span>
			<span class="red">*</span>
		</label>
		<div *ngIf="acceptTerms?.hasError('required') && acceptTerms?.touched" class="invalid-field red">
			{{'You must agree to our Terms of service and Privacy Policy' | translate}}
		</div>
	</div>
	<div class="d-flex align-items-center justify-content-end mt-3">
		<button (click)="captchaRef.execute()" class="btn btn-default-small w-100" [disabled]="!form.valid || !acceptTerms.valid" >{{'Sign Up' | translate}}</button>
	</div>
</form>

<div class="d-flex flex-column align-items-center">
	<div class="both-lined"><p>{{'or use' | translate}}</p></div>
	<div class="social-container">
		<a (click)="goToThirdParty('google')" class="social"><img src="./assets/images/google_color.svg" [width]="16"
		                                                          alt="Google" title="Google"></a>
		<a (click)="goToThirdParty('linkedin')" class="social"><img src="./assets/images/linkedin.svg" [width]="16"
		                                                          alt="LinkedIn" title="LinkedIn"></a>
	</div>
</div>

<re-captcha size="invisible" #captchaRef (resolved)="$event && completeRegister($event)"
            [siteKey]="SITE_ID_NG">
</re-captcha>


import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {NotificationsService, INotificationItem} from "./notifications.service";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

const INFO_I_ICON =
  `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M20 424.2h20V279.8H20c-11 0-20-9-20-20V212c0-11 9-20 20-20h112c11 0 20 9 20 20v212.2h20c11 0 20 9 20 20V492c0 11-9 20-20 20H20c-11 0-20-9-20-20v-47.8c0-11 9-20 20-20zM96 0C56.2 0 24 32.2 24 72s32.2 72 72 72 72-32.2 72-72S135.8 0 96 0z"/></svg>
`;

const EXCLAMATION_ICON = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M176 432c0 44.1-35.9 80-80 80s-80-35.9-80-80 35.9-80 80-80 80 35.9 80 80zM25.3 25.2l13.6 272C39.5 310 50 320 62.8 320h66.3c12.8 0 23.3-10 24-22.8l13.6-272C167.4 11.5 156.5 0 142.8 0H49.2C35.5 0 24.6 11.5 25.3 25.2z"/></svg>`

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  opened: boolean = false;
  
  notifications!: INotificationItem[];
  notificationsCount: number = 0;
  constructor(private eRef: ElementRef, private service: NotificationsService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral('info_i', sanitizer.bypassSecurityTrustHtml(INFO_I_ICON));
    iconRegistry.addSvgIconLiteral('exclamation', sanitizer.bypassSecurityTrustHtml(EXCLAMATION_ICON));
  }

  ngOnInit(): void {
    this.service.loadNotifications();
    this.service.notifications$.subscribe(data => {
      this.notifications = data;
    })
    this.service.notificationsCount$.subscribe(data => {
      this.notificationsCount = data;
    })
  }
  
  open() {
    this.opened = !this.opened;
  }
  
  close() {
    this.opened = false;
  }
  
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.close();
    }
  }
}

<mat-dialog-actions align="end">
	<button type="button" class="btn btn-light btn-sm" mat-button [mat-dialog-close]="false">X</button>
</mat-dialog-actions>

<h2 mat-dialog-title class="font-f" style="margin-top: -50px;">{{'Make a payment' | translate}}</h2>

<mat-dialog-content class="mat-typography" style="padding: 10px 24px; width: 500px;">
	<div id="link-authentication-element"></div>
	<div id="address-element"></div>
	<div id="payment-element"></div>
	
	<div id="checkout"></div>
</mat-dialog-content>

<div *ngIf="!data.is_checkout">
	<button type="button" class="btn btn-accent" mat-button cdkFocusInitial (click)="stripeConfirmPayment()" style="background-color: #43d886; margin-top: 12px;"> {{'Pay' | translate}} {{data.payment_sum | currency}}</button>
</div>


import {Injectable} from "@angular/core";
import {HttpHandler, HttpInterceptor, HttpRequest,  HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Store} from "@ngrx/store";
import {AppState} from "../store/app.reducer";
import {catchError, map} from "rxjs/operators";
import {throwError} from "rxjs";
import {signOut} from "../store/user/user.actions";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
  
  constructor(private store: Store<AppState>, private _snackBar: MatSnackBar) {
  }
  
  intercept(req: HttpRequest<any>, next: HttpHandler) {

    if (req.url.includes('monetizer_api')) {
      req = req.clone({url: req.url.replace('monetizer_api', `${environment.apiUrl}`)});
    }
  
    return next.handle(this.addToken(req))
    .pipe(
        map(response => {
        
          if (response instanceof HttpResponse) {
            const newToken = response.headers.get('Authorization')?.split('Bearer ')[1];
            if (newToken)
              localStorage.setItem('authToken', newToken)
          }
          return response
        }),
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            switch ((<HttpErrorResponse>error).status) {
              case 401:
                return this.handle401Error(error);
              case 500:
                return this.handle500Error(error)
              default:
                return throwError(error)
            }
          } else {
            return throwError(error);
          }
        })
    );
  }
  
  private addToken(req: HttpRequest<any>): HttpRequest<any> {
    
    const token = localStorage.getItem('authToken');
    
    return req.clone({setHeaders: {Authorization: 'Bearer ' + token}});
  }
  
  private handle401Error(error: HttpErrorResponse) {
    this.store.dispatch(signOut());
    return throwError(error)
  }
  
  private handle500Error(error: HttpErrorResponse) {
    this._snackBar.open('Something went wrong.','Close', {duration: 2000, panelClass: ['snack-bar-error']})
    return throwError(error)
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {RegisterFormModel} from "../../../../models/register-form.model";

@Component({
  selector: 'step-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {
  
  selectedMessengerType: string | undefined = undefined;
  messengerTypes: {key: string, label: string}[] = [
    {
      key: 'viber',
      label: 'Viber'
    },
    {
      key: 'signal',
      label: 'Signal'
    },
    {
      key: 'whatsapp',
      label: 'WhatsApp'
    },
    {
      key: 'telegram',
      label: 'Telegram'
    },
    {
      key: 'skype',
      label: 'Skype'
    }
  ]
  
  form!: FormGroup;
  
  
  @Input() formContent: Partial<RegisterFormModel> = {};
  messengerValueCtrl!: FormControl;
  
  @Output() updateParentForm: EventEmitter<{ formData: Partial<RegisterFormModel>, valid: boolean }> = new EventEmitter<{ formData: Partial<RegisterFormModel>, valid: boolean }>();
  constructor() {
  }
  
  ngOnInit(): void {
    this.selectedMessengerType = this.formContent?.messenger_type;
    this.messengerValueCtrl = new FormControl(this.formContent?.messenger_account, [
      Validators.required
    ])
  
    this.messengerValueCtrl.valueChanges.subscribe(data => {
      this.emitFormUpdate(data);
    })
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
      this.messengerValueCtrl.setValue(this.formContent?.messenger_account);
    }, 0)
  }
  
  
  
  changeMessenger(messenger: string) : void {
    switch (messenger) {
      case 'viber':
      case 'whatsapp':
      case 'signal':
        this.messengerValueCtrl = new FormControl('', [
          Validators.required,
          Validators.pattern('[- +()0-9]+'),
          Validators.minLength(5)
        ])
      break;
      case 'telegram':
      case 'skype':
        this.messengerValueCtrl = new FormControl('', [
          Validators.required,
          Validators.minLength(5)
        ])
      break;
    }
  
    this.emitFormUpdate(this.messengerValueCtrl.value);
  
    this.messengerValueCtrl.valueChanges.subscribe(data => {
      this.emitFormUpdate(data);
    })
    
  }
  
  emitFormUpdate(data: string) {
    this.updateParentForm.emit({
      formData: {
        messenger_type: this.selectedMessengerType,
        messenger_account: data
      },
      valid: !!(this.messengerValueCtrl.valid && this.selectedMessengerType)
    })
  }
}

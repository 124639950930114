import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[polarityClass]'
})
export class PolarityClassDirective implements OnInit {

  @Input() count: number = 0;

  constructor(private renderer: Renderer2,
              private elementRef: ElementRef,) { }

  ngOnInit() {
    if (this.count > 0)
      this.renderer.addClass(this.elementRef, 'up')

  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header.component';
import {DomainControlComponent} from './components/domain-control/domain-control.component';
import {ReactiveFormsModule} from "@angular/forms";
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatRippleModule} from '@angular/material/core';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import {RouterModule} from "@angular/router";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {CoreModule} from "../../core.module";
import {NotificationLineComponent} from "./components/notification-line/notification-line.component";
import {HeaderService} from "./header.service";
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationsItemComponent } from './components/notifications/notifications-item/notifications-item.component';
import {
  NotificationsModalComponent
} from "./components/notifications/notifications-modal/notifications-modal.component";
import {DxScrollViewModule} from "devextreme-angular";
import {LanguageSelectorModule} from "../parts/language-selector/language-selector.module";


@NgModule({
  declarations: [
    HeaderComponent,
    DomainControlComponent,
    UserMenuComponent,
    NotificationLineComponent,
    NotificationsComponent,
    NotificationsModalComponent,
    NotificationsItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatRippleModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    CoreModule,
    DxScrollViewModule,
    LanguageSelectorModule
  ],
  exports: [HeaderComponent, NotificationLineComponent],
  providers: [HeaderService]
})
export class HeaderModule {
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'columnLabel'
})
export class ColumnLabelPipe implements PipeTransform {

  transform(value: string): string {
    const labelsRelations = [
      {
        key:'date',
        value: 'Date',
      }, {
        key:'label',
        value: 'Plan'
      }, {
        key: 'product',
        value: 'Product'
      }, {
        key: 'charge_type',
        value: 'Charge Type',
      }, {
        key: 'balance_format',
        value: 'Balance'
      }, {
        key: 'login',
        value: 'Login',
      }, {
        key: 'password',
        value: 'Password'
      }, {
        key: 'threads',
        value: 'Threads'
      }, {
        key: 'pool',
        value: 'Pool'
      }, {
        key: 'pool_type',
        value: 'Pool type'
      }, {
        key: 'sticky_range',
        value: 'Sticky range'
      }, {
        key: 'allowed_ip',
        value: 'Allowed IP'
      }, {
        key: 'add_funds',
        value: 'Add Funds'
      }, {
        key: 'subuser_id',
        value: 'Id'
      }, {
        key: 'created',
        value: 'Created'
      }, {
        key: 'blocked',
        value: 'Blocked'
      }, {
        key: 'add_traffic',
        value: 'Add traffic'
      },
      , {
        key: 'balance',
        value: 'Balance'
      },
    ];
    
    const newValue = labelsRelations.find(label => label?.key === value)
    if (newValue) return newValue.value
    return value;
  }

}

<monetizer-alert [type]="typeOfMessage" [message]="message"></monetizer-alert>
<app-language-selector size="min"></app-language-selector>
<h2 class="text-center my-3">{{'Login' | translate}}</h2>
<div class="text-center">{{'Not a Member yet?' | translate}} <a routerLink="/sign-up">{{'Sign Up' | translate}}</a></div>
<hr>
<form class="w-100 my-3" [formGroup]="form">
  <div class="form-group">
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text"><img src="./assets/images/user.png" alt="" class="img-responsive"></div>
      </div>
      <input formControlName="email" type="email" class="form-control" id="loginEmailGroup" placeholder="{{'Email' | translate}}">
    </div>
    <mat-error *ngIf="email?.hasError('email') && !email?.hasError('required')">
      {{'Please enter a valid email address' | translate}}
    </mat-error>
    <mat-error *ngIf="email?.hasError('required') && email?.touched" >
      {{'Email is required' | translate}}
    </mat-error>
  </div>
  <div class="form-group">
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text"><img src="./assets/images/lock.png" alt="" class="img-responsive"></div>
      </div>
      <input formControlName="password" [attr.type]="isShowPass ? 'text' : 'password'" class="form-control" id="loginPasswordGroup" placeholder="{{'Password' | translate}}">
      <div class="input-group-append">
        <div class="input-group-text cursor-pointer" (click)="showPass($event)">
          <mat-icon >{{ isShowPass ? 'visibility': 'visibility_off'}}</mat-icon>
        </div>
      </div>
    </div>
    <div class="text-right">
      <a routerLink="/forgot-pass" >{{'Forgot password' | translate}}</a>
    </div>
  </div>
  
  
  <button type="submit" class="btn btn-default w-100  " [disabled]="form.invalid || (isLoading | async)" (click)="captchaRef.execute()"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="(isLoading | async)"></span> {{'Log In' | translate}}</button>
  
</form>

<div class="d-flex flex-column align-items-center">
  <div class="both-lined"><p>{{'or use' | translate}}</p></div>
  <div class="social-container">
    <a (click)="goToThirdParty('google')" class="social"><img src="./assets/images/google_color.svg" [width]="16"
                                                              alt="Google" title="Google"></a>
    <a (click)="goToThirdParty('linkedin')" class="social"><img src="./assets/images/linkedin.svg" [width]="16"
                                                                alt="LinkedIn" title="LinkedIn"></a>
  </div>
</div>

<re-captcha size="invisible" #captchaRef (resolved)="$event && loginUser($event)"
            [siteKey]="SITE_ID_NG">
</re-captcha>

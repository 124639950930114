import {createAction, props} from "@ngrx/store";

export const setLanguage = createAction(
  '[Set language]',
  props<{language: string}>()
);

export const setLanguageSuccess = createAction('[Set language success]');

export const setLanguageError = createAction('[Set language error]');

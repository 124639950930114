import {NgModule} from '@angular/core';
import {DoubleBracesPipe} from "./pipes/double-braces.pipe";
import { NumberPolarityPipe } from './pipes/number-polarity.pipe';
import { PolarityClassDirective } from './directives/polarity-class.directive';
import { StatusCodeTagDirective } from './directives/status-code-tag.directive';
import {CommonModule} from "@angular/common";
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { CartItemComponent } from './modules/cart/cart-icon/cart-item.component';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {RouterModule} from "@angular/router";
import {MatBadgeModule} from "@angular/material/badge";
import { DatePickerComponent } from './modules/parts/date-picker/date-picker.component';
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { LoaderComponent } from './modules/parts/loader/loader.component';
import {CartComponent} from "./modules/cart/cart.component";
import { SubscriptionDialogComponent } from './modules/cart/subscription-dialog/subscription-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import { ConfirmModalComponent } from './modules/parts/confirm-modal/confirm-modal.component';
import {CallbackPipe} from "./pipes/callback.pipe";
import { ColumnLabelPipe } from './pipes/column-label.pipe';
import { LocationNamePipe } from './pipes/location-name.pipe';
import {CopyIconComponent} from "./modules/parts/copy-icon/copy-icon.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {
	SelectPaymentMethodDialogComponent
} from "./modules/select-payment-method-dialog/select-payment-method-dialog.component";
import {StripeDialogComponent} from "./modules/stripe-dialog/stripe-dialog.component";
import {PlansApi} from "../products/plans/api/plans.api";
import {PlansFacade} from "../products/plans/plans.facade";
import {ResellerApi} from "../products/reseller/api/reseller.api";
import {ResellerFacade} from "../products/reseller/reseller.facade";
import {CustomerInfoDialogComponent} from "./modules/customer-info-dialog/customer-info-dialog.component";
import { NiceDateFormatPipe } from './pipes/nice-date-format.pipe';
import {PoolTypeNamePipe} from "./pipes/pool-type-name.pipe";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {AccountFacade} from "../account/account.facade";
import {IpCountRoundPipe} from "./pipes/ip-count-round.pipe";

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
		DoubleBracesPipe,
	    NumberPolarityPipe,
	    CallbackPipe,
	    PolarityClassDirective,
	    StatusCodeTagDirective,
	    NotFoundComponent,
	    CartComponent,
	    CartItemComponent,
	    DatePickerComponent,
	    LoaderComponent,
	    SubscriptionDialogComponent,
	    ConfirmModalComponent,
	    ColumnLabelPipe,
	    LocationNamePipe,
	    CopyIconComponent,
	    SelectPaymentMethodDialogComponent,
	    StripeDialogComponent,
	    CustomerInfoDialogComponent,
        NiceDateFormatPipe,
	    PoolTypeNamePipe,
	    IpCountRoundPipe
    ],
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		RouterModule,
		MatBadgeModule,
		MatInputModule,
		MatDatepickerModule,
		ReactiveFormsModule,
		MatDialogModule,
		MatTooltipModule,
		ClipboardModule,
		FormsModule,
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			},
			isolate: false
		})
	],
	exports: [
		DoubleBracesPipe,
		NumberPolarityPipe,
		CallbackPipe,
		PolarityClassDirective,
		StatusCodeTagDirective,
		CartItemComponent,
		DatePickerComponent,
		LoaderComponent,
		ColumnLabelPipe,
		CopyIconComponent,
		NiceDateFormatPipe,
		PoolTypeNamePipe,
		TranslateModule,
		IpCountRoundPipe
	],
	providers: [
		AccountFacade,
		PlansApi,
		PlansFacade,
		ResellerApi,
		ResellerFacade,
	]
})
export class CoreModule {
}

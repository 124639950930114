import {Injectable} from '@angular/core';
import {CartItem} from "../../models/cart-item.model";
import {asyncScheduler, BehaviorSubject, Observable, of, scheduled, Subject} from "rxjs";
import {
  combineLatest, debounceTime, exhaustMap, map, mergeAll, mergeMap, scan, shareReplay, startWith, switchMap, tap
} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {SubscriptionItem} from "../../models/subscription-item.model";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/app.reducer";
import {initPlans} from "../../store/user/user.actions";

export interface StateTree {
  store: CartItem[];
  cart: CartItem[];
  tot: number;
}

@Injectable({
  providedIn: 'root'
})
export class CartService {
  
  private stateTree$ = new BehaviorSubject<StateTree | null>(null);
  // private cartLoad$ = new Subject<CartItem[]>();
  private cartAdd$ = new Subject<CartItem>();
  private cartRemove$ = new Subject<CartItem>();
  private cartClear$ = new Subject();
  
  cartItems: CartItem[] = [];
  totalAmount: number = 0;
  
  requestMultiplier: number = 1000;

  constructor(private httpClient: HttpClient, private store: Store<AppState>) {
    this.initCart();
  }
  
  /**
   * Main application cart Observable
   * This could start with items from local storage or even an API call
   * We use scan peak at the items within the cart and add and remove
   */
  private get cart$(): Observable<CartItem[]> {
    return scheduled([this.cartAdd$, this.cartRemove$, this.cartClear$], asyncScheduler).pipe(
        mergeAll(),
        startWith([] as any),
        scan((acc: CartItem[], item: CartItem) => {
          if (item) {
            if (item.remove) {
              return [...acc.filter((i) => i.plan_id !== item.plan_id)];
            } else {
              if (item.charge_type == 'traffic') {
                item = {...item, amount: item.count * item.price_per_value}
              } else if (item.charge_type == 'requests') {
                item = {...item, amount: (item.count/this.requestMultiplier) * item.price_per_value}
              }
              const existItem = acc.findIndex(elem => elem.plan_id === item.plan_id)
              if (existItem >= 0) {
                let replacedItem;
                if (!item.update_count) {
                  replacedItem = {...item, count: acc[existItem].count + item.count}
                } else {
                  replacedItem = {...item, count: item.count}
                }
                if (replacedItem.charge_type == 'traffic') {
                  replacedItem = {...replacedItem, amount: replacedItem.count * replacedItem.price_per_value}
                } else if (replacedItem.charge_type == 'requests') {
                  replacedItem = {...replacedItem, amount: (replacedItem.count/this.requestMultiplier) * replacedItem.price_per_value}
                }
                acc[existItem] = replacedItem;
                return [...acc]
              }
            }
            return [...acc, item];
          }
          return [];
        }),
        // tap(cart => {
        //   if (cart && cart.length)
        //     localStorage.setItem('cart_items', JSON.stringify(cart))
        // })
    )
  }
  
  /**
   * Calcs all Totals from being piped through the cart Observable
   * When an item gets added or removed it will automatically calc
   */
  private get total$(): Observable<number> {
    return this.cart$.pipe(
        // switchMap(val => {
        //   const cart = JSON.parse(localStorage.getItem('cart_items'));
        //   return of(cart);
        // }),
        map((items) => {
          let total = 0;
          for (const i of items) {
            total += i.amount;
          }
          return total;
        }),
        map((cost) => (cost))
    );
  }
  
  get plans$(): Observable<CartItem[]> {
    return this.state$.pipe(
        map(val => val.store)
    )
  }
  
  /**
   * Main Shopping Cart StateTree
   * Combines all dependencies and maps then to the StateTree Object
   */
  state$: Observable<StateTree> = this.stateTree$.pipe(
      switchMap(() =>
          this.getItems().pipe(
              combineLatest(this.cart$, this.total$),
              debounceTime(0),
          ),
      ),
      map(([store, cart, tot, checkout]: any) => ({
        store,
        cart,
        tot
      })),
      // exhaustMap(state => {
      //   const localCart = JSON.parse(localStorage.getItem('cart_items'));
      //   return of({...state, cart: localCart})
      // }),
      shareReplay(1)
  );
  
  // Mock data service call
  getItems() {
    return this.httpClient.get<{data: any}>('monetizer_api/api/plans/get_plans').pipe(
        // tap(res =>
        //   this.store.dispatch(initPlans({plans: res.data}))
        // ),
        map(response => response.data.map((item: any) => {
          let amount, count = 0;
          
          if(item.charge_type == 'requests' && item.min_requests_count > 0 || item.charge_type == 'traffic' && item.min_traffic_count > 0) {
            count = item.charge_type == 'requests' ? item.min_requests_count : item.min_traffic_count;
            amount = count * item.price_per_value;
            if(item.charge_type == 'requests') amount *= this.requestMultiplier;
          } else {
            amount = item.min_payment_amount;
            count = amount/item.price_per_value;
            if(item.charge_type == 'requests') count *= this.requestMultiplier;
          }
          count = Math.ceil(count);
          
          // return {
          //   ...item,
          //   amount: item.price_per_value,
          //   count: item.charge_type == 'requests' ? 1000 : 1
          // }

          return {
            ...item,
            count: count,
            min_count: count,
            amount: amount,
          }

        })),
    );
  }
  
  clearCart() {
    this.cartClear$.next();
  }
  
  // facade for next of cartAdd subject
  addCartItem(item: CartItem) {
    this.cartAdd$.next({ ...item});
  }
  // facade for next of cartRemove subject
  removeCartItem(item: CartItem) {
    this.cartRemove$.next({ ...item, remove: true });
  }

  checkout(state: StateTree) {
    const items = state.cart.map(item => {
          return {
            plan_id: item.plan_id,
            amount: item.amount,
            charge_type: item.charge_type,
            count: item.count
          }
    })
    const postData = {
      total_amount: state.tot,
      items
    }
    return this.httpClient.post<any>('monetizer_api/api/payments/paddle/get_payment_link', postData);
  }
  
  cancelSubscription(plan_id: number) {
    return this.httpClient.post<boolean>('monetizer_api/api/payments/fastspring/cancel_subscription', {plan_id})
  }
  
  paddleCheckout(state: StateTree) {
    const items = state.cart.map(item => {
      return {
        plan_id: item.plan_id,
        amount: item.amount,
        charge_type: item.charge_type,
        count: item.count
      }
    })
    const postData = {
      total_amount: state.tot,
      items
    }
    return this.httpClient.post<any>('monetizer_api/api/payments/paddle/get_payment_link', postData);
  }
  
  fsCheckout(state: StateTree) {
    const items = state.cart.map(item => {
      return {
        plan_id: item.plan_id,
        amount: item.amount,
        charge_type: item.charge_type,
        count: item.count
      }
    })
    const postData = {
      total_amount: state.tot,
      items
    }
    return this.httpClient.post<any>('monetizer_api/api/payments/fastspring/get_payment_payload', postData);
  }
  
  fsSubscription({plan_id, amount, charge_type, count, count_limit}: SubscriptionItem) {
    const postData = {
      plan_id,
      amount,
      charge_type,
      count,
      count_limit
    }
    return this.httpClient.post<any>('monetizer_api/api/payments/fastspring/get_subscription_payload', postData);
  }
  
  
  private initCart() {
    // const cart = JSON.parse(localStorage.getItem('cart_items'));
    // if (cart && cart.length) {
    //   cart.forEach((elem: CartItem) => {
    //     this.cartAdd$.next(elem);
    //   })
    // }
  }
  
}

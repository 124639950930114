<h2 mat-dialog-title class="font-f">Customer information</h2>
<mat-dialog-content class="mat-typography">
	
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12 p-0">
				
				<form [formGroup]="customerInfoForm" class="mb-4">
					<div class="row">
						
						<div class="col-sm-12 mb-4">
							<label class="form-label" for="emailInput">email *</label>
							<input class="form-control form-control-sm" type="email" formControlName="email" id="emailInput">
						</div>
						<div class="col-sm-12 mb-4">
							<label class="form-label" for="nameInput">name / business name</label>
							<input class="form-control form-control-sm" type="text" formControlName="name" id="nameInput">
						</div>
						<div class="col-sm-6 mb-4">
							<label class="form-label" for="countryInput">country</label>
							<input class="form-control form-control-sm" type="text" formControlName="country" id="countryInput">
						</div>
						<div class="col-sm-6 mb-4">
							<label class="form-label" for="stateInput">State, county, province, or region.</label>
							<input class="form-control form-control-sm" type="text" formControlName="state" id="stateInput">
						</div>
						<div class="col-sm-12 mb-4">
							<label class="form-label" for="addressLine1Input">Address line 1 (e.g., street, PO Box, or company name).</label>
							<input class="form-control form-control-sm" type="text" formControlName="address_line_1" id="addressLine1Input">
						</div>
						<div class="col-sm-12 mb-4">
							<label class="form-label" for="addressLine2Input">Address line 2 (e.g., apartment, suite, unit, or building).</label>
							<input class="form-control form-control-sm" type="text" formControlName="address_line_2" value="rotating" id="addressLine2Input">
						</div>
						<div class="col-6 mb-4">
							<label class="form-label" for="postalCodeInput">ZIP or postal code.</label>
							<input class="form-control form-control-sm" type="text" formControlName="postal_code" value="rotating" id="postalCodeInput">
						</div>
						<div class="col-6 mb-4">
							<label class="form-label" for="phoneInput">phone</label>
							<input class="form-control form-control-sm" type="text" formControlName="phone" value="rotating" id="phoneInput">
						</div>
					</div>
				</form>
			
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button type="button" class="btn btn-light btn-sm" mat-button [mat-dialog-close]="false">
		Cancel</button>
	<button type="button" class="btn btn-default-small dx-button-text" mat-button [disabled]="!customerInfoForm.valid" [mat-dialog-close]="customerInfoForm.value"
	        cdkFocusInitial>Submit</button>
</mat-dialog-actions>

import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-copy-icon',
  templateUrl: './copy-icon.component.html',
  styleUrls: ['./copy-icon.component.scss']
})
export class CopyIconComponent implements OnInit {

  @Input() value: string = '';
  
  @ViewChild('tooltip') tooltip: any;
  
  copiedText: string = 'Click to copy';
  constructor(private translateService: TranslateService) {
    this.translateService.get('Click to copy').subscribe(translated => { this.copiedText = translated})
  }

  ngOnInit(): void {
  }
  
  onCopied() {
    this.translateService.get('Copied!').subscribe(translated => { this.copiedText = translated})
    setTimeout(() => {
      this.translateService.get('Click to copy').subscribe(translated => { this.copiedText = translated})
    },2000)
    this.tooltip.show();
  }

}
